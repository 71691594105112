@charset "UTF-8";
/*
 * Silicon | Multipurpose Bootstrap 5 Template & UI Kit
 * Copyright 2022 Createx Studio
 * Theme styles
*/
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");
.text-gradient-primary {
  background: linear-gradient(to right, #4c82f7, #0d6efd, #0dcaf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

:root {
  --si-blue: #0d6efd;
  --si-indigo: #6610f2;
  --si-purple: #6f42c1;
  --si-pink: #d63384;
  --si-red: #dc3545;
  --si-orange: #fd7e14;
  --si-yellow: #ffc107;
  --si-green: #198754;
  --si-teal: #20c997;
  --si-cyan: #0dcaf0;
  --si-black: #000;
  --si-white: #fff;
  --si-gray: #9397ad;
  --si-gray-dark: #33354d;
  --si-gray-100: #f3f6ff;
  --si-gray-200: #eff2fc;
  --si-gray-300: #e2e5f1;
  --si-gray-400: #d4d7e5;
  --si-gray-500: #b4b7c9;
  --si-gray-600: #9397ad;
  --si-gray-700: #565973;
  --si-gray-800: #33354d;
  --si-gray-900: #000000;
  --si-primary: #4c82f7;
  --si-secondary: #eff2fc;
  --si-success: #22c55e;
  --si-info: #4c82f7;
  --si-warning: #ffba08;
  --si-danger: #ef4444;
  --si-light: #fff;
  --si-dark: #000000;
  --si-primary-rgb: 76, 130, 247;
  --si-secondary-rgb: 239, 242, 252;
  --si-success-rgb: 34, 197, 94;
  --si-info-rgb: 76, 130, 247; 
  --si-warning-rgb: 255, 186, 8;
  --si-danger-rgb: 239, 68, 68;
  --si-light-rgb: 255, 255, 255;
  --si-dark-rgb: 11, 15, 25;
  --si-white-rgb: 255, 255, 255;
  --si-black-rgb: 0, 0, 0;
  --si-body-color-rgb: 86, 89, 115;
  --si-body-bg-rgb: 255, 255, 255;
  --si-font-sans-serif: "Manrope", sans-serif;
  --si-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --si-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --si-body-font-family: var(--si-font-sans-serif);
  --si-body-font-size: 1rem;
  --si-body-font-weight: 400;
  --si-body-line-height: 1.6;
  --si-body-color: #565973;
  --si-body-bg: #fff;
  --si-border-width: 1px;
  --si-border-style: solid;
  --si-border-color: #e2e5f1;
  --si-border-color-translucent: rgba(0, 0, 0, 0.175);
  --si-border-radius: 0.375rem;
  --si-border-radius-sm: 0.25rem;
  --si-border-radius-lg: 0.5rem;
  --si-border-radius-xl: 1rem;
  --si-border-radius-2xl: 2rem;
  --si-border-radius-pill: 50rem;
  --si-heading-color: #000000;
  --si-link-color: #4c82f7;
  --si-link-hover-color: #2567f5;
  --si-code-color: #e3116c;
  --si-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--si-body-font-family);
  font-size: var(--si-body-font-size);
  font-weight: var(--si-body-font-weight);
  line-height: var(--si-body-line-height);
  color: var(--si-body-color);
  text-align: var(--si-body-text-align);
  background-color: var(--si-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 0 0;
  color: var(--si-border-color);
  border: 0;
  border-top: 1px solid;
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 800;
  line-height: 1.3;
  color: var(--si-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 800;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--si-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--si-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--si-link-hover-color);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--si-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  color: #33354d;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--si-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #000000;
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--si-gray-600);
  text-align: left;
}

th {
  font-weight: 700;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

.no-border-hover {
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove box-shadow if any */
}

.no-border-hover:hover, .no-border-hover:focus {
  border: none !important; /* Ensure border is removed on hover/focus */
  box-shadow: none !important; /* Ensure box-shadow is removed on hover/focus */
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 400;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.5rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 800;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 800;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 800;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 800;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 800;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.3875rem + 1.65vw);
  font-weight: 800;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.625rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 1rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: var(--si-gray-600);
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.5rem;
  background-color: var(--si-body-bg);
  border: 0 solid var(--si-border-color);
  border-radius: var(--si-border-radius-lg);
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875rem;
  color: var(--si-gray-600);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --si-gutter-x: 1.5rem;
  --si-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--si-gutter-x) * 0.5);
  padding-left: calc(var(--si-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 500px) {
  .container-sm, .container {
    max-width: 97%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --si-gutter-x: 1.5rem;
  --si-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--si-gutter-y));
  margin-right: calc(-0.5 * var(--si-gutter-x));
  margin-left: calc(-0.5 * var(--si-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--si-gutter-x) * 0.5);
  padding-left: calc(var(--si-gutter-x) * 0.5);
  margin-top: var(--si-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --si-gutter-x: 0;
}

.g-0,
.gy-0 {
  --si-gutter-y: 0;
}

.g-1,
.gx-1 {
  --si-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --si-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --si-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --si-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --si-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --si-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --si-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --si-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --si-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --si-gutter-y: 3rem;
}

@media (min-width: 500px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --si-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --si-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --si-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --si-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --si-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --si-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --si-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --si-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --si-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --si-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --si-gutter-x: 3rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --si-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --si-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --si-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --si-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --si-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --si-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --si-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --si-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --si-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --si-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --si-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --si-gutter-x: 3rem;
  }
  .g-md-5,
.gy-md-5 {
    --si-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --si-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --si-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --si-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --si-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --si-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --si-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --si-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --si-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --si-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --si-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --si-gutter-x: 3rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --si-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --si-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --si-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --si-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --si-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --si-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --si-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --si-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --si-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --si-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --si-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --si-gutter-x: 3rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --si-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --si-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --si-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --si-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --si-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --si-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --si-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --si-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --si-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --si-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --si-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --si-gutter-x: 3rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --si-gutter-y: 3rem;
  }
}
.table {
  --si-table-color: var(--si-body-color);
  --si-table-bg: transparent;
  --si-table-border-color: var(--si-border-color);
  --si-table-accent-bg: transparent;
  --si-table-striped-color: var(--si-body-color);
  --si-table-striped-bg: rgba(0, 0, 0, 0.0375);
  --si-table-active-color: var(--si-body-color);
  --si-table-active-bg: rgba(0, 0, 0, 0.05);
  --si-table-hover-color: var(--si-body-color);
  --si-table-hover-bg: rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--si-table-color);
  vertical-align: top;
  border-color: var(--si-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--si-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--si-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.375rem 0.375rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --si-table-accent-bg: var(--si-table-striped-bg);
  color: var(--si-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --si-table-accent-bg: var(--si-table-striped-bg);
  color: var(--si-table-striped-color);
}

.table-active {
  --si-table-accent-bg: var(--si-table-active-bg);
  color: var(--si-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --si-table-accent-bg: var(--si-table-hover-bg);
  color: var(--si-table-hover-color);
}

.table-dark {
  --si-table-color: #fff;
  --si-table-bg: #000000;
  --si-table-border-color: #30333c;
  --si-table-striped-bg: #141822;
  --si-table-striped-color: #fff;
  --si-table-active-bg: #171b25;
  --si-table-active-color: #fff;
  --si-table-hover-bg: #171b25;
  --si-table-hover-color: #fff;
  color: var(--si-table-color);
  border-color: var(--si-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 499.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #000000;
}

.col-form-label {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 600;
  line-height: 1.6;
  color: #000000;
}

.col-form-label-lg {
  padding-top: calc(0.785rem + 1px);
  padding-bottom: calc(0.785rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.475rem + 1px);
  padding-bottom: calc(0.475rem + 1px);
  font-size: 0.75rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--si-gray-600);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #565973;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d4d7e5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #565973;
  background-color: #fff;
  border-color: rgba(76, 130, 247, 0.35);
  outline: 0;
  box-shadow: inset 0 0 0 transparent, 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.2);
}
.form-control::-webkit-date-and-time-value {
  height: 1.6em;
}
.form-control::-moz-placeholder {
  color: #b4b7c9;
  opacity: 1;
}
.form-control::placeholder {
  color: #b4b7c9;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f3f6ff;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: 0.625rem 1rem;
  margin: -0.625rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: #565973;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.form-control::file-selector-button {
  padding: 0.625rem 1rem;
  margin: -0.625rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: #565973;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f2f2f2;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.625rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: #565973;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 0.95rem + 2px);
  padding: 0.475rem 0.875rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.475rem 0.875rem;
  margin: -0.475rem -0.875rem;
  -webkit-margin-end: 0.875rem;
  margin-inline-end: 0.875rem;
}
.form-control-sm::file-selector-button {
  padding: 0.475rem 0.875rem;
  margin: -0.475rem -0.875rem;
  -webkit-margin-end: 0.875rem;
  margin-inline-end: 0.875rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1.57rem + 2px);
  padding: 0.785rem 1.125rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.785rem 1.125rem;
  margin: -0.785rem -1.125rem;
  -webkit-margin-end: 1.125rem;
  margin-inline-end: 1.125rem;
}
.form-control-lg::file-selector-button {
  padding: 0.785rem 1.125rem;
  margin: -0.785rem -1.125rem;
  -webkit-margin-end: 1.125rem;
  margin-inline-end: 1.125rem;
}

textarea.form-control {
  min-height: calc(1.6em + 1.25rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.6em + 0.95rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.6em + 1.57rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.625rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.6em;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.6em;
  border-radius: 0.375rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.625rem 3rem 0.625rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #565973;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23565973' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #d4d7e5;
  border-radius: 0.375rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: rgba(76, 130, 247, 0.35);
  outline: 0;
  box-shadow: inset 0 0 0 transparent, 0 0 0 0 rgba(76, 130, 247, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #f3f6ff;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #565973;
}

.form-select-sm {
  padding-top: 0.475rem;
  padding-bottom: 0.475rem;
  padding-left: 0.875rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.785rem;
  padding-bottom: 0.785rem;
  padding-left: 1.125rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem;
  margin-bottom: 0.25rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5rem;
}

.form-check-reverse {
  padding-right: 1.5rem;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5rem;
  margin-left: 0;
}

.form-check-input {
  width: 1rem;
  height: 1rem;
  margin-top: 0.3rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid #b4b7c9;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.1875em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: rgba(76, 130, 247, 0.35);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(76, 130, 247, 0.25);
}
.form-check-input:checked {
  background-color: #4c82f7;
  border-color: #4c82f7;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #4c82f7;
  border-color: #4c82f7;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.55;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.55;
}

.form-switch {
  padding-left: 3.375em;
}
.form-switch .form-check-input {
  width: 2.875em;
  margin-left: -3.375em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2.875em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 3.375em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -3.375em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.2);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.2);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #4c82f7;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #2063f5;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e9ebf4;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0 0 transparent;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #4c82f7;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #2063f5;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e9ebf4;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0 0 transparent;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #b4b7c9;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #b4b7c9;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.7;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.7;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.7;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #565973;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #d4d7e5;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.785rem 1.125rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.475rem 0.875rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #22c55e;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #000;
  background-color: rgba(34, 197, 94, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #22c55e;
  padding-right: calc(1.6em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2322c55e'%3e%3cpath d='M10 1.667c-4.595 0-8.333 3.738-8.333 8.333S5.405 18.333 10 18.333s8.333-3.738 8.333-8.333S14.595 1.667 10 1.667zm0 15c-3.676 0-6.667-2.991-6.667-6.667S6.324 3.333 10 3.333 16.667 6.324 16.667 10 13.676 16.667 10 16.667zm-1.668-5.345L6.416 9.41 5.24 10.59l3.094 3.088 5.588-5.588-1.178-1.178-4.412 4.412z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.3125rem) center;
  background-size: calc(0.8em + 0.625rem) calc(0.8em + 0.625rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #22c55e;
  box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 1.25rem);
  background-position: top calc(0.4em + 0.3125rem) right calc(0.4em + 0.3125rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #22c55e;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23565973' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2322c55e'%3e%3cpath d='M10 1.667c-4.595 0-8.333 3.738-8.333 8.333S5.405 18.333 10 18.333s8.333-3.738 8.333-8.333S14.595 1.667 10 1.667zm0 15c-3.676 0-6.667-2.991-6.667-6.667S6.324 3.333 10 3.333 16.667 6.324 16.667 10 13.676 16.667 10 16.667zm-1.668-5.345L6.416 9.41 5.24 10.59l3.094 3.088 5.588-5.588-1.178-1.178-4.412 4.412z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.8em + 0.625rem) calc(0.8em + 0.625rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #22c55e;
  box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.6em + 1.25rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #22c55e;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #22c55e;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #22c55e;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #ef4444;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #000;
  background-color: rgba(239, 68, 68, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef4444;
  padding-right: calc(1.6em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ef4444'%3e%3cpath d='M7.643 13.535L10 11.178l2.357 2.357 1.178-1.178L11.178 10l2.357-2.357-1.178-1.178L10 8.822 7.643 6.465 6.465 7.643 8.822 10l-2.357 2.357 1.178 1.178zM10 18.333c4.595 0 8.333-3.738 8.333-8.333S14.595 1.667 10 1.667 1.667 5.405 1.667 10 5.405 18.333 10 18.333zm0-15c3.676 0 6.667 2.991 6.667 6.667S13.676 16.667 10 16.667 3.333 13.676 3.333 10 6.324 3.333 10 3.333z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.3125rem) center;
  background-size: calc(0.8em + 0.625rem) calc(0.8em + 0.625rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ef4444;
  box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 1.25rem);
  background-position: top calc(0.4em + 0.3125rem) right calc(0.4em + 0.3125rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ef4444;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23565973' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ef4444'%3e%3cpath d='M7.643 13.535L10 11.178l2.357 2.357 1.178-1.178L11.178 10l2.357-2.357-1.178-1.178L10 8.822 7.643 6.465 6.465 7.643 8.822 10l-2.357 2.357 1.178 1.178zM10 18.333c4.595 0 8.333-3.738 8.333-8.333S14.595 1.667 10 1.667 1.667 5.405 1.667 10 5.405 18.333 10 18.333zm0-15c3.676 0 6.667 2.991 6.667 6.667S13.676 16.667 10 16.667 3.333 13.676 3.333 10 6.324 3.333 10 3.333z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.8em + 0.625rem) calc(0.8em + 0.625rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #ef4444;
  box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.6em + 1.25rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ef4444;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #ef4444;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef4444;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  --si-btn-padding-x: 1.75rem;
  --si-btn-padding-y: 0.625rem;
  --si-btn-font-family: ;
  --si-btn-font-size: 0.875rem;
  --si-btn-font-weight: 600;
  --si-btn-line-height: 1.6;
  --si-btn-color: #565973;
  --si-btn-bg: transparent;
  --si-btn-border-width: 1px;
  --si-btn-border-color: transparent;
  --si-btn-border-radius: 0.375rem;
  --si-btn-box-shadow: unset;
  --si-btn-disabled-opacity: 0.65;
  --si-btn-focus-box-shadow: 0 0 0 0 rgba(var(--si-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--si-btn-padding-y) var(--si-btn-padding-x);
  font-family: var(--si-btn-font-family);
  font-size: var(--si-btn-font-size);
  font-weight: var(--si-btn-font-weight);
  line-height: var(--si-btn-line-height);
  color: var(--si-btn-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--si-btn-border-width) solid var(--si-btn-border-color);
  border-radius: var(--si-btn-border-radius);
  background-color: var(--si-btn-bg);
  box-shadow: var(--si-btn-box-shadow);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--si-btn-hover-color);
  background-color: var(--si-btn-hover-bg);
  border-color: var(--si-btn-hover-border-color);
}
.btn-check:focus + .btn, .btn:focus {
  color: var(--si-btn-hover-color);
  background-color: var(--si-btn-hover-bg);
  border-color: var(--si-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--si-btn-box-shadow), var(--si-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  color: var(--si-btn-active-color);
  background-color: var(--si-btn-active-bg);
  border-color: var(--si-btn-active-border-color);
  box-shadow: var(--si-btn-active-shadow);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
  box-shadow: var(--si-btn-active-shadow), var(--si-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--si-btn-disabled-color);
  pointer-events: none;
  background-color: var(--si-btn-disabled-bg);
  border-color: var(--si-btn-disabled-border-color);
  opacity: var(--si-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --si-btn-color: #000;
  --si-btn-bg: #4c82f7;
  --si-btn-border-color: #4c82f7;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #6795f8;
  --si-btn-hover-border-color: #5e8ff8;
  --si-btn-focus-shadow-rgb: 65, 111, 210;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #709bf9;
  --si-btn-active-border-color: #5e8ff8;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #000;
  --si-btn-disabled-bg: #4c82f7;
  --si-btn-disabled-border-color: #4c82f7;
}

.btn-secondary {
  --si-btn-color: #000;
  --si-btn-bg: #eff2fc;
  --si-btn-border-color: #eff2fc;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #f1f4fc;
  --si-btn-hover-border-color: #f1f3fc;
  --si-btn-focus-shadow-rgb: 203, 206, 214;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #f2f5fd;
  --si-btn-active-border-color: #f1f3fc;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #000;
  --si-btn-disabled-bg: #eff2fc;
  --si-btn-disabled-border-color: #eff2fc;
}

.btn-success {
  --si-btn-color: #000;
  --si-btn-bg: #22c55e;
  --si-btn-border-color: #22c55e;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #43ce76;
  --si-btn-hover-border-color: #38cb6e;
  --si-btn-focus-shadow-rgb: 29, 167, 80;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #4ed17e;
  --si-btn-active-border-color: #38cb6e;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #000;
  --si-btn-disabled-bg: #22c55e;
  --si-btn-disabled-border-color: #22c55e;
}

.btn-info {
  --si-btn-color: #000;
  --si-btn-bg: #4c82f7;
  --si-btn-border-color: #4c82f7;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #6795f8;
  --si-btn-hover-border-color: #5e8ff8;
  --si-btn-focus-shadow-rgb: 65, 111, 210;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #709bf9;
  --si-btn-active-border-color: #5e8ff8;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #000;
  --si-btn-disabled-bg: #4c82f7;
  --si-btn-disabled-border-color: #4c82f7;
}

.btn-warning {
  --si-btn-color: #000;
  --si-btn-bg: #ffba08;
  --si-btn-border-color: #ffba08;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #ffc42d;
  --si-btn-hover-border-color: #ffc121;
  --si-btn-focus-shadow-rgb: 217, 158, 7;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #ffc839;
  --si-btn-active-border-color: #ffc121;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #000;
  --si-btn-disabled-bg: #ffba08;
  --si-btn-disabled-border-color: #ffba08;
}

.btn-danger {
  --si-btn-color: #000;
  --si-btn-bg: #ef4444;
  --si-btn-border-color: #ef4444;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #f16060;
  --si-btn-hover-border-color: #f15757;
  --si-btn-focus-shadow-rgb: 203, 58, 58;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #f26969;
  --si-btn-active-border-color: #f15757;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #000;
  --si-btn-disabled-bg: #ef4444;
  --si-btn-disabled-border-color: #ef4444;
}

.btn-light {
  --si-btn-color: #000;
  --si-btn-bg: #fff;
  --si-btn-border-color: #fff;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: white;
  --si-btn-hover-border-color: white;
  --si-btn-focus-shadow-rgb: 217, 217, 217;
  --si-btn-active-color: #000;
  --si-btn-active-bg: white;
  --si-btn-active-border-color: white;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #000;
  --si-btn-disabled-bg: #fff;
  --si-btn-disabled-border-color: #fff;
}

.btn-dark {
  --si-btn-color: #fff;
  --si-btn-bg: #000000;
  --si-btn-border-color: #000000;
  --si-btn-hover-color: #fff;
  --si-btn-hover-bg: #090d15;
  --si-btn-hover-border-color: #090c14;
  --si-btn-focus-shadow-rgb: 48, 51, 60;
  --si-btn-active-color: #fff;
  --si-btn-active-bg: #090c14;
  --si-btn-active-border-color: #080b13;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #fff;
  --si-btn-disabled-bg: #000000;
  --si-btn-disabled-border-color: #000000;
}

.btn-outline-primary {
  --si-btn-color: #4c82f7;
  --si-btn-border-color: #4c82f7;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #4c82f7;
  --si-btn-hover-border-color: #4c82f7;
  --si-btn-focus-shadow-rgb: 76, 130, 247;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #4c82f7;
  --si-btn-active-border-color: #4c82f7;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #4c82f7;
  --si-btn-disabled-bg: transparent;
  --si-gradient: none;
}

.btn-outline-secondary {
  --si-btn-color: #eff2fc;
  --si-btn-border-color: #eff2fc;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #eff2fc;
  --si-btn-hover-border-color: #eff2fc;
  --si-btn-focus-shadow-rgb: 239, 242, 252;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #eff2fc;
  --si-btn-active-border-color: #eff2fc;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #eff2fc;
  --si-btn-disabled-bg: transparent;
  --si-gradient: none;
}

.btn-outline-success {
  --si-btn-color: #22c55e;
  --si-btn-border-color: #22c55e;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #22c55e;
  --si-btn-hover-border-color: #22c55e;
  --si-btn-focus-shadow-rgb: 34, 197, 94;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #22c55e;
  --si-btn-active-border-color: #22c55e;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #22c55e;
  --si-btn-disabled-bg: transparent;
  --si-gradient: none;
}

.btn-outline-info {
  --si-btn-color: #4c82f7;
  --si-btn-border-color: #4c82f7;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #4c82f7;
  --si-btn-hover-border-color: #4c82f7;
  --si-btn-focus-shadow-rgb: 76, 130, 247;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #4c82f7;
  --si-btn-active-border-color: #4c82f7;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #4c82f7;
  --si-btn-disabled-bg: transparent;
  --si-gradient: none;
}

.btn-outline-warning {
  --si-btn-color: #ffba08;
  --si-btn-border-color: #ffba08;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #ffba08;
  --si-btn-hover-border-color: #ffba08;
  --si-btn-focus-shadow-rgb: 255, 186, 8;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #ffba08;
  --si-btn-active-border-color: #ffba08;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #ffba08;
  --si-btn-disabled-bg: transparent;
  --si-gradient: none;
}

.btn-outline-danger {
  --si-btn-color: #ef4444;
  --si-btn-border-color: #ef4444;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #ef4444;
  --si-btn-hover-border-color: #ef4444;
  --si-btn-focus-shadow-rgb: 239, 68, 68;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #ef4444;
  --si-btn-active-border-color: #ef4444;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #ef4444;
  --si-btn-disabled-bg: transparent;
  --si-gradient: none;
}

.btn-outline-light {
  --si-btn-color: #fff;
  --si-btn-border-color: #fff;
  --si-btn-hover-color: #000;
  --si-btn-hover-bg: #fff;
  --si-btn-hover-border-color: #fff;
  --si-btn-focus-shadow-rgb: 255, 255, 255;
  --si-btn-active-color: #000;
  --si-btn-active-bg: #fff;
  --si-btn-active-border-color: #fff;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #fff;
  --si-btn-disabled-bg: transparent;
  --si-gradient: none;
}

.btn-outline-dark {
  --si-btn-color: #000000;
  --si-btn-border-color: #000000;
  --si-btn-hover-color: #fff;
  --si-btn-hover-bg: #000000;
  --si-btn-hover-border-color: #000000;
  --si-btn-focus-shadow-rgb: 11, 15, 25;
  --si-btn-active-color: #fff;
  --si-btn-active-bg: #000000;
  --si-btn-active-border-color: #000000;
  --si-btn-active-shadow: unset;
  --si-btn-disabled-color: #000000;
  --si-btn-disabled-bg: transparent;
  --si-gradient: none;
}

.btn-link {
  --si-btn-font-weight: 400;
  --si-btn-color: var(--si-link-color);
  --si-btn-bg: transparent;
  --si-btn-border-color: transparent;
  --si-btn-hover-color: var(--si-link-hover-color);
  --si-btn-hover-border-color: transparent;
  --si-btn-active-border-color: transparent;
  --si-btn-disabled-color: #9397ad;
  --si-btn-disabled-border-color: transparent;
  --si-btn-box-shadow: none;
  text-decoration: underline;
}
.btn-link:hover, .btn-link:focus {
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
  --si-btn-padding-y: 0.785rem;
  --si-btn-padding-x: 2rem;
  --si-btn-font-size: 1rem;
  --si-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --si-btn-padding-y: 0.475rem;
  --si-btn-padding-x: 1.25rem;
  --si-btn-font-size: 0.75rem;
  --si-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --si-dropdown-min-width: 12rem;
  --si-dropdown-padding-x: 0;
  --si-dropdown-padding-y: 0.5rem;
  --si-dropdown-spacer: 0.25rem;
  --si-dropdown-font-size: 0.875rem;
  --si-dropdown-color: #565973;
  --si-dropdown-bg: #fff;
  --si-dropdown-border-color: #fff;
  --si-dropdown-border-radius: 0.5rem;
  --si-dropdown-border-width: 1px;
  --si-dropdown-inner-border-radius: calc(0.5rem - 1px);
  --si-dropdown-divider-bg: #e2e5f1;
  --si-dropdown-divider-margin-y: 0.375rem;
  --si-dropdown-box-shadow: 0 0.275rem 1.25rem rgba(11, 15, 25, 0.05), 0 0.25rem 0.5625rem rgba(11, 15, 25, 0.03);
  --si-dropdown-link-color: #33354d;
  --si-dropdown-link-hover-color: #4c82f7;
  --si-dropdown-link-hover-bg: transparent;
  --si-dropdown-link-active-color: #4c82f7;
  --si-dropdown-link-active-bg: transparent;
  --si-dropdown-link-disabled-color: #9397ad;
  --si-dropdown-item-padding-x: 1rem;
  --si-dropdown-item-padding-y: 0.375rem;
  --si-dropdown-header-color: #000000;
  --si-dropdown-header-padding-x: 1rem;
  --si-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--si-dropdown-min-width);
  padding: var(--si-dropdown-padding-y) var(--si-dropdown-padding-x);
  margin: 0;
  font-size: var(--si-dropdown-font-size);
  color: var(--si-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--si-dropdown-bg);
  background-clip: padding-box;
  border: var(--si-dropdown-border-width) solid var(--si-dropdown-border-color);
  border-radius: var(--si-dropdown-border-radius);
  box-shadow: var(--si-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--si-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 500px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--si-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--si-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--si-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--si-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--si-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--si-dropdown-item-padding-y) var(--si-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--si-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--si-dropdown-link-hover-color);
  background-color: var(--si-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--si-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--si-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--si-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--si-dropdown-header-padding-y) var(--si-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--si-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--si-dropdown-item-padding-y) var(--si-dropdown-item-padding-x);
  color: var(--si-dropdown-link-color);
}

.dropdown-menu-dark, .dark-mode .dropdown-menu {
  --si-dropdown-color: rgba(255, 255, 255, 0.7);
  --si-dropdown-bg: linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), #000000;
  --si-dropdown-border-color: rgba(255, 255, 255, 0.14);
  --si-dropdown-box-shadow: ;
  --si-dropdown-link-color: rgba(255, 255, 255, 0.85);
  --si-dropdown-link-hover-color: #4c82f7;
  --si-dropdown-divider-bg: rgba(255, 255, 255, 0.14);
  --si-dropdown-link-hover-bg: transparent;
  --si-dropdown-link-active-color: #4c82f7;
  --si-dropdown-link-active-bg: transparent;
  --si-dropdown-link-disabled-color: rgba(255, 255, 255, 0.4);
  --si-dropdown-header-color: #fff;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: unset;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --si-nav-link-padding-x: 1rem;
  --si-nav-link-padding-y: 0.535rem;
  --si-nav-link-font-weight: 600;
  --si-nav-link-color: #33354d;
  --si-nav-link-hover-color: #4c82f7;
  --si-nav-link-disabled-color: #9397ad;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--si-nav-link-padding-y) var(--si-nav-link-padding-x);
  font-size: var(--si-nav-link-font-size);
  font-weight: var(--si-nav-link-font-weight);
  color: var(--si-nav-link-color);
  text-decoration: none;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--si-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--si-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --si-nav-tabs-border-width: 0;
  --si-nav-tabs-border-color: transparent;
  --si-nav-tabs-border-radius: 0.375rem;
  --si-nav-tabs-link-hover-border-color: transparent;
  --si-nav-tabs-link-active-color: #fff;
  --si-nav-tabs-link-active-bg: #4c82f7;
  --si-nav-tabs-link-active-border-color: transparent;
  border-bottom: var(--si-nav-tabs-border-width) solid var(--si-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(var(--si-nav-tabs-border-width) * -1);
  background: none;
  border: var(--si-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--si-nav-tabs-border-radius);
  border-top-right-radius: var(--si-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--si-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--si-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--si-nav-tabs-link-active-color);
  background-color: var(--si-nav-tabs-link-active-bg);
  border-color: var(--si-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(var(--si-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --si-nav-pills-border-radius: 50rem;
  --si-nav-pills-link-active-color: #fff;
  --si-nav-pills-link-active-bg: #4c82f7;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--si-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--si-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--si-nav-pills-link-active-color);
  background-color: var(--si-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --si-navbar-padding-x: 0;
  --si-navbar-padding-y: 2rem;
  --si-navbar-color: #33354d;
  --si-navbar-hover-color: #4c82f7;
  --si-navbar-disabled-color: #9397ad;
  --si-navbar-active-color: #4c82f7;
  --si-navbar-brand-padding-y: 0.5rem;
  --si-navbar-brand-margin-end: 1rem;
  --si-navbar-brand-font-size: 1.375rem;
  --si-navbar-brand-color: #000000;
  --si-navbar-brand-hover-color: #000000;
  --si-navbar-nav-link-padding-x: 0.875rem;
  --si-navbar-toggler-padding-y: 0.625rem;
  --si-navbar-toggler-padding-x: 0.25rem;
  --si-navbar-toggler-font-size: 1.125rem;
  --si-navbar-toggler-icon-bg: initial;
  --si-navbar-toggler-border-color: transparent;
  --si-navbar-toggler-border-radius: 0;
  --si-navbar-toggler-focus-width: 0;
  --si-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--si-navbar-padding-y) var(--si-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--si-navbar-brand-padding-y);
  padding-bottom: var(--si-navbar-brand-padding-y);
  margin-right: var(--si-navbar-brand-margin-end);
  font-size: var(--si-navbar-brand-font-size);
  color: var(--si-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--si-navbar-brand-hover-color);
}

.navbar-nav {
  --si-nav-link-padding-x: 0;
  --si-nav-link-padding-y: 0.535rem;
  --si-nav-link-color: var(--si-navbar-color);
  --si-nav-link-hover-color: var(--si-navbar-hover-color);
  --si-nav-link-disabled-color: var(--si-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--si-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.535rem;
  padding-bottom: 0.535rem;
  color: var(--si-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--si-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--si-navbar-toggler-padding-y) var(--si-navbar-toggler-padding-x);
  font-size: var(--si-navbar-toggler-font-size);
  line-height: 1;
  color: var(--si-navbar-color);
  background-color: transparent;
  border: var(--si-border-width) solid var(--si-navbar-toggler-border-color);
  border-radius: var(--si-navbar-toggler-border-radius);
  transition: var(--si-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--si-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--si-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--si-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 500px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--si-navbar-nav-link-padding-x);
    padding-left: var(--si-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--si-navbar-nav-link-padding-x);
    padding-left: var(--si-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--si-navbar-nav-link-padding-x);
    padding-left: var(--si-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--si-navbar-nav-link-padding-x);
    padding-left: var(--si-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--si-navbar-nav-link-padding-x);
    padding-left: var(--si-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--si-navbar-nav-link-padding-x);
  padding-left: var(--si-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --si-navbar-color: rgba(255, 255, 255, 0.85);
  --si-navbar-hover-color: #4c82f7;
  --si-navbar-disabled-color: rgba(255, 255, 255, 0.4);
  --si-navbar-active-color: #4c82f7;
  --si-navbar-brand-color: #fff;
  --si-navbar-brand-hover-color: #fff;
  --si-navbar-toggler-border-color: transparent;
  --si-navbar-toggler-icon-bg: initial;
}

.card {
  --si-card-spacer-y: 1.5rem;
  --si-card-spacer-x: 1.5rem;
  --si-card-title-spacer-y: 0.75rem;
  --si-card-border-width: 1px;
  --si-card-border-color: #e2e5f1;
  --si-card-border-radius: 0.5rem;
  --si-card-box-shadow: ;
  --si-card-inner-border-radius: calc(0.5rem - 1px);
  --si-card-cap-padding-y: 1.125rem;
  --si-card-cap-padding-x: 1.5rem;
  --si-card-cap-bg: transparent;
  --si-card-cap-color: ;
  --si-card-height: ;
  --si-card-color: ;
  --si-card-bg: #fff;
  --si-card-img-overlay-padding: 1.5rem;
  --si-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--si-card-height);
  word-wrap: break-word;
  background-color: var(--si-card-bg);
  background-clip: border-box;
  border: var(--si-card-border-width) solid var(--si-card-border-color);
  border-radius: var(--si-card-border-radius);
  box-shadow: var(--si-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--si-card-inner-border-radius);
  border-top-right-radius: var(--si-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--si-card-inner-border-radius);
  border-bottom-left-radius: var(--si-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--si-card-spacer-y) var(--si-card-spacer-x);
  color: var(--si-card-color);
}

.card-title {
  margin-bottom: var(--si-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--si-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--si-card-spacer-x);
}

.card-header {
  padding: var(--si-card-cap-padding-y) var(--si-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--si-card-cap-color);
  background-color: var(--si-card-cap-bg);
  border-bottom: var(--si-card-border-width) solid var(--si-card-border-color);
}
.card-header:first-child {
  border-radius: var(--si-card-inner-border-radius) var(--si-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--si-card-cap-padding-y) var(--si-card-cap-padding-x);
  color: var(--si-card-cap-color);
  background-color: var(--si-card-cap-bg);
  border-top: var(--si-card-border-width) solid var(--si-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--si-card-inner-border-radius) var(--si-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--si-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--si-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--si-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--si-card-bg);
  border-bottom-color: var(--si-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--si-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--si-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--si-card-img-overlay-padding);
  border-radius: var(--si-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--si-card-inner-border-radius);
  border-top-right-radius: var(--si-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--si-card-inner-border-radius);
  border-bottom-left-radius: var(--si-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--si-card-group-margin);
}
@media (min-width: 500px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --si-accordion-color: #000;
  --si-accordion-bg: #fff;
  --si-accordion-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border-radius 0.15s ease;
  --si-accordion-border-color: #e2e5f1;
  --si-accordion-border-width: 1px;
  --si-accordion-border-radius: 0.5rem;
  --si-accordion-inner-border-radius: calc(0.5rem - 1px);
  --si-accordion-btn-padding-x: 1.5rem;
  --si-accordion-btn-padding-y: 1rem;
  --si-accordion-btn-color: var(--si-body-color);
  --si-accordion-btn-bg: var(--si-accordion-bg);
  --si-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%2333354d'%3e%3cpath d='M225.813 48.907L128 146.72 30.187 48.907 0 79.093l128 128 128-128z'/%3e%3c/svg%3e");
  --si-accordion-btn-icon-width: 0.625rem;
  --si-accordion-btn-icon-transform: rotate(-180deg);
  --si-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --si-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23fff'%3e%3cpath d='M225.813 48.907L128 146.72 30.187 48.907 0 79.093l128 128 128-128z'/%3e%3c/svg%3e");
  --si-accordion-btn-focus-border-color: rgba(76, 130, 247, 0.35);
  --si-accordion-btn-focus-box-shadow: unset;
  --si-accordion-body-padding-x: 1.5rem;
  --si-accordion-body-padding-y: 1rem;
  --si-accordion-active-color: #000000;
  --si-accordion-active-bg: #fff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--si-accordion-btn-padding-y) var(--si-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--si-accordion-btn-color);
  text-align: left;
  background-color: var(--si-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--si-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--si-accordion-active-color);
  background-color: var(--si-accordion-active-bg);
  box-shadow: inset 0 calc(var(--si-accordion-border-width) * -1) 0 var(--si-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--si-accordion-btn-active-icon);
  transform: var(--si-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--si-accordion-btn-icon-width);
  height: var(--si-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--si-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--si-accordion-btn-icon-width);
  transition: var(--si-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--si-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--si-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--si-accordion-color);
  background-color: var(--si-accordion-bg);
  border: var(--si-accordion-border-width) solid var(--si-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--si-accordion-border-radius);
  border-top-right-radius: var(--si-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--si-accordion-inner-border-radius);
  border-top-right-radius: var(--si-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--si-accordion-border-radius);
  border-bottom-left-radius: var(--si-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--si-accordion-inner-border-radius);
  border-bottom-left-radius: var(--si-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--si-accordion-border-radius);
  border-bottom-left-radius: var(--si-accordion-border-radius);
}

.accordion-body {
  padding: var(--si-accordion-body-padding-y) var(--si-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  --si-breadcrumb-padding-x: 0;
  --si-breadcrumb-padding-y: 0;
  --si-breadcrumb-margin-bottom: 1rem;
  --si-breadcrumb-font-size: 0.875rem;
  --si-breadcrumb-bg: ;
  --si-breadcrumb-border-radius: ;
  --si-breadcrumb-divider-color: var(--si-gray-600);
  --si-breadcrumb-item-padding-x: 0.625rem;
  --si-breadcrumb-item-active-color: var(--si-primary);
  display: flex;
  flex-wrap: wrap;
  padding: var(--si-breadcrumb-padding-y) var(--si-breadcrumb-padding-x);
  margin-bottom: var(--si-breadcrumb-margin-bottom);
  font-size: var(--si-breadcrumb-font-size);
  list-style: none;
  background-color: var(--si-breadcrumb-bg);
  border-radius: var(--si-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--si-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--si-breadcrumb-item-padding-x);
  color: var(--si-breadcrumb-divider-color);
  content: var(--si-breadcrumb-divider, "\ea55") /* rtl: var(--si-breadcrumb-divider, "\ed3f") */;
}
.breadcrumb-item.active {
  color: var(--si-breadcrumb-item-active-color);
}

.pagination {
  --si-pagination-padding-x: 0.875rem;
  --si-pagination-padding-y: 0.45rem;
  --si-pagination-font-size: 0.875rem;
  --si-pagination-color: #33354d;
  --si-pagination-bg: transparent;
  --si-pagination-border-width: 0;
  --si-pagination-border-color: #e2e5f1;
  --si-pagination-border-radius: 0.375rem;
  --si-pagination-hover-color: #4c82f7;
  --si-pagination-hover-bg: #eff2fc;
  --si-pagination-hover-border-color: #e2e5f1;
  --si-pagination-focus-color: #4c82f7;
  --si-pagination-focus-bg: #eff2fc;
  --si-pagination-focus-box-shadow: 0 0 0 0 rgba(76, 130, 247, 0.25);
  --si-pagination-active-color: #fff;
  --si-pagination-active-bg: #4c82f7;
  --si-pagination-active-border-color: #4c82f7;
  --si-pagination-disabled-color: #9397ad;
  --si-pagination-disabled-bg: transparent;
  --si-pagination-disabled-border-color: #e2e5f1;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--si-pagination-padding-y) var(--si-pagination-padding-x);
  font-size: var(--si-pagination-font-size);
  color: var(--si-pagination-color);
  text-decoration: none;
  background-color: var(--si-pagination-bg);
  border: var(--si-pagination-border-width) solid var(--si-pagination-border-color);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--si-pagination-hover-color);
  background-color: var(--si-pagination-hover-bg);
  border-color: var(--si-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--si-pagination-focus-color);
  background-color: var(--si-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--si-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--si-pagination-active-color);
  background-color: var(--si-pagination-active-bg);
  border-color: var(--si-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--si-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--si-pagination-disabled-bg);
  border-color: var(--si-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--si-pagination-border-radius);
  border-bottom-left-radius: var(--si-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--si-pagination-border-radius);
  border-bottom-right-radius: var(--si-pagination-border-radius);
}

.pagination-lg {
  --si-pagination-padding-x: 1.125rem;
  --si-pagination-padding-y: 0.625rem;
  --si-pagination-font-size: 1.125rem;
  --si-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --si-pagination-padding-x: 0.65rem;
  --si-pagination-padding-y: 0.265rem;
  --si-pagination-font-size: 0.875rem;
  --si-pagination-border-radius: 0.25rem;
}

.badge {
  --si-badge-padding-x: 0.6em;
  --si-badge-padding-y: 0.35em;
  --si-badge-font-size: 0.8125em;
  --si-badge-font-weight: 600;
  --si-badge-color: #fff;
  --si-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--si-badge-padding-y) var(--si-badge-padding-x);
  font-size: var(--si-badge-font-size);
  font-weight: var(--si-badge-font-weight);
  line-height: 1;
  color: var(--si-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--si-badge-border-radius, 0);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --si-alert-bg: transparent;
  --si-alert-padding-x: 1rem;
  --si-alert-padding-y: 1rem;
  --si-alert-margin-bottom: 1rem;
  --si-alert-color: inherit;
  --si-alert-border-color: transparent;
  --si-alert-border: 1px solid var(--si-alert-border-color);
  --si-alert-border-radius: 0.5rem;
  position: relative;
  padding: var(--si-alert-padding-y) var(--si-alert-padding-x);
  margin-bottom: var(--si-alert-margin-bottom);
  color: var(--si-alert-color);
  background-color: var(--si-alert-bg);
  border: var(--si-alert-border);
  border-radius: var(--si-alert-border-radius, 0);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --si-alert-color: #4475de;
  --si-alert-bg: #edf3fe;
  --si-alert-border-color: #a6c1fb;
}
.alert-primary .alert-link {
  color: #365eb2;
}

.alert-secondary {
  --si-alert-color: #d7dae3;
  --si-alert-bg: #fdfeff;
  --si-alert-border-color: #f7f9fe;
}
.alert-secondary .alert-link {
  color: #acaeb6;
}

.alert-success {
  --si-alert-color: #1fb155;
  --si-alert-bg: #e9f9ef;
  --si-alert-border-color: #91e2af;
}
.alert-success .alert-link {
  color: #198e44;
}

.alert-info {
  --si-alert-color: #4475de;
  --si-alert-bg: #edf3fe;
  --si-alert-border-color: #a6c1fb;
}
.alert-info .alert-link {
  color: #365eb2;
}

.alert-warning {
  --si-alert-color: #e6a707;
  --si-alert-bg: #fff8e6;
  --si-alert-border-color: #ffdd84;
}
.alert-warning .alert-link {
  color: #b88606;
}

.alert-danger {
  --si-alert-color: #d73d3d;
  --si-alert-bg: #fdecec;
  --si-alert-border-color: #f7a2a2;
}
.alert-danger .alert-link {
  color: #ac3131;
}

.alert-light {
  --si-alert-color: #e6e6e6;
  --si-alert-bg: white;
  --si-alert-border-color: white;
}
.alert-light .alert-link {
  color: #b8b8b8;
}

.alert-dark {
  --si-alert-color: #0a0e17;
  --si-alert-bg: #e7e7e8;
  --si-alert-border-color: #85878c;
}
.alert-dark .alert-link {
  color: #080b12;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --si-progress-height: 1rem;
  --si-progress-font-size: 0.75rem;
  --si-progress-bg: #e9ebf4;
  --si-progress-border-radius: 50rem;
  --si-progress-box-shadow: inset 0 0 0 transparent;
  --si-progress-bar-color: #fff;
  --si-progress-bar-bg: #4c82f7;
  --si-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--si-progress-height);
  overflow: hidden;
  font-size: var(--si-progress-font-size);
  background-color: var(--si-progress-bg);
  border-radius: var(--si-progress-border-radius);
  box-shadow: var(--si-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--si-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--si-progress-bar-bg);
  transition: var(--si-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--si-progress-height) var(--si-progress-height);
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 0.75em;
  height: 0.75em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/0.75em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --si-toast-padding-x: 1rem;
  --si-toast-padding-y: 0.75rem;
  --si-toast-spacing: 1.5rem;
  --si-toast-max-width: 350px;
  --si-toast-font-size: 0.875rem;
  --si-toast-color: ;
  --si-toast-bg: #fff;
  --si-toast-border-width: 1px;
  --si-toast-border-color: transparent;
  --si-toast-border-radius: 0.5rem;
  --si-toast-box-shadow: 0 0.275rem 1.25rem rgba(11, 15, 25, 0.05), 0 0.25rem 0.5625rem rgba(11, 15, 25, 0.03);
  --si-toast-header-color: var(--si-heading-color);
  --si-toast-header-bg: transparent;
  --si-toast-header-border-color: var(--si-border-color);
  width: var(--si-toast-max-width);
  max-width: 100%;
  font-size: var(--si-toast-font-size);
  color: var(--si-toast-color);
  pointer-events: auto;
  background-color: var(--si-toast-bg);
  background-clip: padding-box;
  border: var(--si-toast-border-width) solid var(--si-toast-border-color);
  box-shadow: var(--si-toast-box-shadow);
  border-radius: var(--si-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: 1090;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--si-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--si-toast-padding-y) var(--si-toast-padding-x);
  color: var(--si-toast-header-color);
  background-color: var(--si-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--si-toast-border-width) solid var(--si-toast-header-border-color);
  border-top-left-radius: calc(var(--si-toast-border-radius) - var(--si-toast-border-width));
  border-top-right-radius: calc(var(--si-toast-border-radius) - var(--si-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(var(--si-toast-padding-x) * -0.5);
  margin-left: var(--si-toast-padding-x);
}

.toast-body {
  padding: var(--si-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --si-modal-zindex: 1055;
  --si-modal-width: 500px;
  --si-modal-padding: 1.5rem;
  --si-modal-margin: 0.5rem;
  --si-modal-color: ;
  --si-modal-bg: #fff;
  --si-modal-border-color: var(--si-border-color-translucent);
  --si-modal-border-width: 0;
  --si-modal-border-radius: 0.5rem;
  --si-modal-box-shadow: 0 0.275rem 1.25rem rgba(11, 15, 25, 0.05), 0 0.25rem 0.5625rem rgba(11, 15, 25, 0.03);
  --si-modal-inner-border-radius: 0.5rem;
  --si-modal-header-padding-x: 1.5rem;
  --si-modal-header-padding-y: 1.125rem;
  --si-modal-header-padding: 1.125rem 1.5rem;
  --si-modal-header-border-color: #e2e5f1;
  --si-modal-header-border-width: 1px;
  --si-modal-title-line-height: 1.6;
  --si-modal-footer-gap: 0.75rem;
  --si-modal-footer-bg: ;
  --si-modal-footer-border-color: #e2e5f1;
  --si-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--si-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--si-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.2s ease-out;
  transform: scale(0.9);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--si-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--si-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--si-modal-color);
  pointer-events: auto;
  background-color: var(--si-modal-bg);
  background-clip: padding-box;
  border: var(--si-modal-border-width) solid var(--si-modal-border-color);
  border-radius: var(--si-modal-border-radius);
  box-shadow: var(--si-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --si-backdrop-zindex: 1050;
  --si-backdrop-bg: #000;
  --si-backdrop-opacity: 0.65;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--si-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--si-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--si-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--si-modal-header-padding);
  border-bottom: var(--si-modal-header-border-width) solid var(--si-modal-header-border-color);
  border-top-left-radius: var(--si-modal-inner-border-radius);
  border-top-right-radius: var(--si-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--si-modal-header-padding-y) * 0.5) calc(var(--si-modal-header-padding-x) * 0.5);
  margin: calc(var(--si-modal-header-padding-y) * -0.5) calc(var(--si-modal-header-padding-x) * -0.5) calc(var(--si-modal-header-padding-y) * -0.5) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--si-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--si-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--si-modal-padding) - var(--si-modal-footer-gap) * 0.5);
  background-color: var(--si-modal-footer-bg);
  border-top: var(--si-modal-footer-border-width) solid var(--si-modal-footer-border-color);
  border-bottom-right-radius: var(--si-modal-inner-border-radius);
  border-bottom-left-radius: var(--si-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--si-modal-footer-gap) * 0.5);
}

@media (min-width: 500px) {
  .modal {
    --si-modal-margin: 1.75rem;
    --si-modal-box-shadow: 0 0.275rem 1.25rem rgba(11, 15, 25, 0.05), 0 0.25rem 0.5625rem rgba(11, 15, 25, 0.03);
  }
  .modal-dialog {
    max-width: var(--si-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --si-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --si-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --si-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 499.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --si-tooltip-zindex: 1080;
  --si-tooltip-max-width: 200px;
  --si-tooltip-padding-x: 0.5rem;
  --si-tooltip-padding-y: 0.25rem;
  --si-tooltip-margin: ;
  --si-tooltip-font-size: 0.8125rem;
  --si-tooltip-color: #fff;
  --si-tooltip-bg: #000;
  --si-tooltip-border-radius: 0.25rem;
  --si-tooltip-opacity: 0.9;
  --si-tooltip-arrow-width: 0.8rem;
  --si-tooltip-arrow-height: 0.4rem;
  z-index: var(--si-tooltip-zindex);
  display: block;
  padding: var(--si-tooltip-arrow-height);
  margin: var(--si-tooltip-margin);
  font-family: var(--si-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--si-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--si-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--si-tooltip-arrow-width);
  height: var(--si-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--si-tooltip-arrow-height) calc(var(--si-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--si-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--si-tooltip-arrow-height);
  height: var(--si-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--si-tooltip-arrow-width) * 0.5) var(--si-tooltip-arrow-height) calc(var(--si-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--si-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--si-tooltip-arrow-width) * 0.5) var(--si-tooltip-arrow-height);
  border-bottom-color: var(--si-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--si-tooltip-arrow-height);
  height: var(--si-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--si-tooltip-arrow-width) * 0.5) 0 calc(var(--si-tooltip-arrow-width) * 0.5) var(--si-tooltip-arrow-height);
  border-left-color: var(--si-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--si-tooltip-max-width);
  padding: var(--si-tooltip-padding-y) var(--si-tooltip-padding-x);
  color: var(--si-tooltip-color);
  text-align: center;
  background-color: var(--si-tooltip-bg);
  border-radius: var(--si-tooltip-border-radius, 0);
}

.popover {
  --si-popover-zindex: 1070;
  --si-popover-max-width: 276px;
  --si-popover-font-size: 0.875rem;
  --si-popover-bg: #fff;
  --si-popover-border-width: 1px;
  --si-popover-border-color: #e2e5f1;
  --si-popover-border-radius: 0.5rem;
  --si-popover-inner-border-radius: calc(0.5rem - 1px);
  --si-popover-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03);
  --si-popover-header-padding-x: 1rem;
  --si-popover-header-padding-y: 0.75rem;
  --si-popover-header-font-size: 1rem;
  --si-popover-header-color: var(--si-heading-color);
  --si-popover-header-bg: transparent;
  --si-popover-body-padding-x: 1rem;
  --si-popover-body-padding-y: 1rem;
  --si-popover-body-color: #565973;
  --si-popover-arrow-width: 1rem;
  --si-popover-arrow-height: 0.5rem;
  --si-popover-arrow-border: var(--si-popover-border-color);
  z-index: var(--si-popover-zindex);
  display: block;
  max-width: var(--si-popover-max-width);
  font-family: var(--si-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--si-popover-font-size);
  word-wrap: break-word;
  background-color: var(--si-popover-bg);
  background-clip: padding-box;
  border: var(--si-popover-border-width) solid var(--si-popover-border-color);
  border-radius: var(--si-popover-border-radius);
  box-shadow: var(--si-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--si-popover-arrow-width);
  height: var(--si-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(var(--si-popover-arrow-height) * -1 - var(--si-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--si-popover-arrow-height) calc(var(--si-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--si-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--si-popover-border-width);
  border-top-color: var(--si-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(var(--si-popover-arrow-height) * -1 - var(--si-popover-border-width));
  width: var(--si-popover-arrow-height);
  height: var(--si-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--si-popover-arrow-width) * 0.5) var(--si-popover-arrow-height) calc(var(--si-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--si-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--si-popover-border-width);
  border-right-color: var(--si-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(var(--si-popover-arrow-height) * -1 - var(--si-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--si-popover-arrow-width) * 0.5) var(--si-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--si-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--si-popover-border-width);
  border-bottom-color: var(--si-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--si-popover-arrow-width);
  margin-left: calc(var(--si-popover-arrow-width) * -0.5);
  content: "";
  border-bottom: var(--si-popover-border-width) solid var(--si-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(var(--si-popover-arrow-height) * -1 - var(--si-popover-border-width));
  width: var(--si-popover-arrow-height);
  height: var(--si-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--si-popover-arrow-width) * 0.5) 0 calc(var(--si-popover-arrow-width) * 0.5) var(--si-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--si-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--si-popover-border-width);
  border-left-color: var(--si-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--si-popover-header-padding-y) var(--si-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--si-popover-header-font-size);
  color: var(--si-popover-header-color);
  background-color: var(--si-popover-header-bg);
  border-bottom: var(--si-popover-border-width) solid var(--si-popover-border-color);
  border-top-left-radius: var(--si-popover-inner-border-radius);
  border-top-right-radius: var(--si-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--si-popover-body-padding-y) var(--si-popover-body-padding-x);
  color: var(--si-popover-body-color);
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--si-spinner-width);
  height: var(--si-spinner-height);
  vertical-align: var(--si-spinner-vertical-align);
  border-radius: 50%;
  -webkit-animation: var(--si-spinner-animation-speed) linear infinite var(--si-spinner-animation-name);
  animation: var(--si-spinner-animation-speed) linear infinite var(--si-spinner-animation-name);
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --si-spinner-width: 2rem;
  --si-spinner-height: 2rem;
  --si-spinner-vertical-align: -0.125em;
  --si-spinner-border-width: 0.15em;
  --si-spinner-animation-speed: 0.75s;
  --si-spinner-animation-name: spinner-border;
  border: var(--si-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --si-spinner-width: 1rem;
  --si-spinner-height: 1rem;
  --si-spinner-border-width: 0.1em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --si-spinner-width: 2rem;
  --si-spinner-height: 2rem;
  --si-spinner-vertical-align: -0.125em;
  --si-spinner-animation-speed: 0.75s;
  --si-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --si-spinner-width: 1rem;
  --si-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --si-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --si-offcanvas-width: 21rem;
  --si-offcanvas-height: 30vh;
  --si-offcanvas-padding-x: 1.5rem;
  --si-offcanvas-padding-y: 1.5rem;
  --si-offcanvas-color: ;
  --si-offcanvas-bg: #fff;
  --si-offcanvas-border-width: 0;
  --si-offcanvas-border-color: var(--si-border-color-translucent);
  --si-offcanvas-box-shadow: 0 0.275rem 1.25rem rgba(11, 15, 25, 0.05), 0 0.25rem 0.5625rem rgba(11, 15, 25, 0.03);
}

@media (max-width: 499.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--si-offcanvas-color);
    visibility: hidden;
    background-color: var(--si-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--si-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 499.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 499.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 499.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (max-width: 499.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--si-offcanvas-width);
    border-right: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 499.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--si-offcanvas-width);
    border-left: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 499.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 499.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-top: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 500px) {
  .offcanvas-sm {
    --si-offcanvas-height: auto;
    --si-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--si-offcanvas-color);
    visibility: hidden;
    background-color: var(--si-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--si-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--si-offcanvas-width);
    border-right: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--si-offcanvas-width);
    border-left: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-top: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --si-offcanvas-height: auto;
    --si-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--si-offcanvas-color);
    visibility: hidden;
    background-color: var(--si-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--si-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--si-offcanvas-width);
    border-right: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--si-offcanvas-width);
    border-left: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-top: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --si-offcanvas-height: auto;
    --si-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--si-offcanvas-color);
    visibility: hidden;
    background-color: var(--si-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--si-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--si-offcanvas-width);
    border-right: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--si-offcanvas-width);
    border-left: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-top: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --si-offcanvas-height: auto;
    --si-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--si-offcanvas-color);
    visibility: hidden;
    background-color: var(--si-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--si-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--si-offcanvas-width);
    border-right: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--si-offcanvas-width);
    border-left: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--si-offcanvas-height);
    max-height: 100%;
    border-top: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --si-offcanvas-height: auto;
    --si-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--si-offcanvas-color);
  visibility: hidden;
  background-color: var(--si-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--si-offcanvas-box-shadow);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--si-offcanvas-width);
  border-right: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--si-offcanvas-width);
  border-left: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--si-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--si-offcanvas-height);
  max-height: 100%;
  border-top: var(--si-offcanvas-border-width) solid var(--si-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.65;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--si-offcanvas-padding-y) var(--si-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--si-offcanvas-padding-y) * 0.5) calc(var(--si-offcanvas-padding-x) * 0.5);
  margin-top: calc(var(--si-offcanvas-padding-y) * -0.5);
  margin-right: calc(var(--si-offcanvas-padding-x) * -0.5);
  margin-bottom: calc(var(--si-offcanvas-padding-y) * -0.5);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--si-offcanvas-padding-y) var(--si-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.35;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.12;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.12;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.88) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.88) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}

@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(76, 130, 247, var(--si-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(239, 242, 252, var(--si-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(34, 197, 94, var(--si-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(76, 130, 247, var(--si-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 186, 8, var(--si-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(239, 68, 68, var(--si-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--si-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(11, 15, 25, var(--si-bg-opacity, 1)) !important;
}

.link-primary {
  color: #4c82f7 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #709bf9 !important;
}

.link-secondary {
  color: #eff2fc !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #f2f5fd !important;
}

.link-success {
  color: #22c55e !important;
}
.link-success:hover, .link-success:focus {
  color: #4ed17e !important;
}

.link-info {
  color: #4c82f7 !important;
}
.link-info:hover, .link-info:focus {
  color: #709bf9 !important;
}

.link-warning {
  color: #ffba08 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffc839 !important;
}

.link-danger {
  color: #ef4444 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #f26969 !important;
}

.link-light {
  color: #fff !important;
}
.link-light:hover, .link-light:focus {
  color: white !important;
}

.link-dark {
  color: #000000 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #090c14 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--si-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --si-aspect-ratio: 100%;
}

.ratio-4x3 {
  --si-aspect-ratio: 75%;
}

.ratio-16x9 {
  --si-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --si-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 500px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-8 {
  opacity: 0.08 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-35 {
  opacity: 0.35 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.275rem 1.25rem rgba(11, 15, 25, 0.05), 0 0.25rem 0.5625rem rgba(11, 15, 25, 0.03) !important;
}

.shadow-sm {
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03) !important;
}

.shadow-lg {
  box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(11, 15, 25, 0.1), 0 0.25rem 0.8125rem -0.125rem rgba(11, 15, 25, 0.06) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-primary {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9) !important;
}

.shadow-secondary {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(11, 15, 25, 0.15) !important;
}

.shadow-info {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9) !important;
}

.shadow-success {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(34, 197, 94, 0.9) !important;
}

.shadow-warning {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(255, 186, 8, 0.9) !important;
}

.shadow-danger {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(239, 68, 68, 0.9) !important;
}

.shadow-dark {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(11, 15, 25, 0.9) !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: rgba(76, 130, 247, 0.35) !important;
}

.border-success {
  border-color: rgba(34, 197, 94, 0.35) !important;
}

.border-info {
  border-color: rgba(76, 130, 247, 0.35) !important;
}

.border-warning {
  border-color: rgba(255, 186, 8, 0.35) !important;
}

.border-danger {
  border-color: rgba(239, 68, 68, 0.35) !important;
}

.border-light {
  border-color: rgba(255, 255, 255, 0.14) !important;
}

.border-dark {
  border-color: rgba(11, 15, 25, 0.35) !important;
}

.border-white {
  border-color: rgba(255, 255, 255, 0.35) !important;
}

.border-1 {
  --si-border-width: 1px;
}

.border-2 {
  --si-border-width: 2px;
}

.border-3 {
  --si-border-width: 3px;
}

.border-4 {
  --si-border-width: 4px;
}

.border-5 {
  --si-border-width: 5px;
}

.border-opacity-10 {
  --si-border-opacity: 0.1;
}

.border-opacity-25 {
  --si-border-opacity: 0.25;
}

.border-opacity-50 {
  --si-border-opacity: 0.5;
}

.border-opacity-75 {
  --si-border-opacity: 0.75;
}

.border-opacity-100 {
  --si-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-1 {
  width: 1px !important;
}

.w-2 {
  width: 2px !important;
}

.w-3 {
  width: 3px !important;
}

.w-33 {
  width: 33.33% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-1 {
  height: 1px !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--si-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-xl {
  font-size: 1.25rem !important;
}

.fs-lg {
  font-size: 1.125rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 800 !important;
}

.fw-semibold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-medium {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.4 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 1.8 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #4c82f7 !important;
}

.text-secondary {
  color: #eff2fc !important;
}

.text-success {
  color: #22c55e !important;
}

.text-info {
  color: #4c82f7 !important;
}

.text-warning {
  color: #ffba08 !important;
}

.text-danger {
  color: #ef4444 !important;
}

.text-light {
  color: #fff !important;
}

.text-dark {
  color: #000000 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #565973 !important;
}

.text-muted {
  color: var(--si-gray-600) !important;
}

.text-nav {
  color: #33354d !important;
}

.text-border {
  color: #e2e5f1 !important;
}

.text-reset {
  color: inherit !important;
}

.text-opacity-25 {
  --si-text-opacity: 0.25;
}

.text-opacity-50 {
  --si-text-opacity: 0.5;
}

.text-opacity-75 {
  --si-text-opacity: 0.75;
}

.text-opacity-100 {
  --si-text-opacity: 1;
}

.bg-primary {
  --si-bg-opacity: 1;
  background-color: rgba(var(--si-primary-rgb), var(--si-bg-opacity)) !important;
}

.bg-secondary {
  --si-bg-opacity: 1;
  background-color: #f3f6ff !important;
}

.bg-success {
  --si-bg-opacity: 1;
  background-color: rgba(var(--si-success-rgb), var(--si-bg-opacity)) !important;
}

.bg-info {
  --si-bg-opacity: 1;
  background-color: rgba(var(--si-info-rgb), var(--si-bg-opacity)) !important;
}

.bg-warning {
  --si-bg-opacity: 1;
  background-color: rgba(var(--si-warning-rgb), var(--si-bg-opacity)) !important;
}

.bg-danger {
  --si-bg-opacity: 1;
  background-color: rgba(var(--si-danger-rgb), var(--si-bg-opacity)) !important;
}

.bg-light {
  --si-bg-opacity: 1;
  background-color: rgba(var(--si-light-rgb), var(--si-bg-opacity)) !important;
}

.bg-dark {
  --si-bg-opacity: 1;
  background-color: #000000 !important;
}

.bg-black {
  --si-bg-opacity: 1;
  background-color: #000 !important;
}

.bg-white {
  --si-bg-opacity: 1;
  background-color: rgba(var(--si-white-rgb), var(--si-bg-opacity)) !important;
}

.bg-body {
  --si-bg-opacity: 1;
  background-color: rgba(var(--si-body-bg-rgb), var(--si-bg-opacity)) !important;
}

.bg-transparent {
  --si-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --si-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --si-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --si-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --si-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --si-bg-opacity: 1;
}

.bg-gradient {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0)) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--si-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--si-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--si-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--si-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--si-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--si-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--si-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--si-border-radius) !important;
  border-top-right-radius: var(--si-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-end {
  border-top-right-radius: var(--si-border-radius) !important;
  border-bottom-right-radius: var(--si-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--si-border-radius) !important;
  border-bottom-left-radius: var(--si-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start {
  border-bottom-left-radius: var(--si-border-radius) !important;
  border-top-left-radius: var(--si-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.bg-faded-primary {
  background-color: rgba(76, 130, 247, 0.12) !important;
}

.bg-faded-success {
  background-color: rgba(34, 197, 94, 0.12) !important;
}

.bg-faded-info {
  background-color: rgba(76, 130, 247, 0.12) !important;
}

.bg-faded-warning {
  background-color: rgba(255, 186, 8, 0.12) !important;
}

.bg-faded-danger {
  background-color: rgba(239, 68, 68, 0.12) !important;
}

.bg-faded-light {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.bg-faded-dark {
  background-color: rgba(11, 15, 25, 0.12) !important;
}

.bg-size-cover {
  background-size: cover !important;
}

.bg-size-contain {
  background-size: contain !important;
}

.bg-position-center {
  background-position: center !important;
}

.bg-position-center-y {
  background-position: left center !important;
}

.bg-position-center-x {
  background-position: center top !important;
}

.bg-position-top-end {
  background-position: top right !important;
}

.bg-position-top-start {
  background-position: top left !important;
}

.bg-position-top-center {
  background-position: top center !important;
}

.bg-position-center-end {
  background-position: center right !important;
}

.bg-position-center-start {
  background-position: center left !important;
}

.bg-position-center-bottom {
  background-position: center bottom !important;
}

.bg-position-bottom-end {
  background-position: bottom right !important;
}

.bg-position-bottom-start {
  background-position: bottom left !important;
}

.bg-position-bottom-center {
  background-position: bottom center !important;
}

.bg-repeat-0 {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-attachment-fixed {
  background-attachment: fixed !important;
}

.bg-gradient-primary {
  background: linear-gradient(90deg, #0d6efd 0%, #0dcaf0 100%) !important;
}

.bg-gradient-primary-translucent {
  background: linear-gradient(180deg, rgba(76, 130, 247, 0) 0%, rgba(76, 130, 247, 0.6) 120%) !important;
}

.bg-gradient-dark-translucent {
  background: linear-gradient(180deg, rgba(11, 15, 25, 0.58) 0%, rgba(11, 15, 25, 0.78) 100%) !important;
}

.bg-image-none {
  background-image: none !important;
}

.text-shadow {
  text-shadow: 0 0.0625rem #000000 !important;
}

.table-layout-fixed {
  table-layout: fixed !important;
}

.zindex-1 {
  z-index: 1 !important;
}

.zindex-2 {
  z-index: 2 !important;
}

.zindex-3 {
  z-index: 3 !important;
}

.zindex-4 {
  z-index: 4 !important;
}

.zindex-5 {
  z-index: 5 !important;
}

@media (min-width: 500px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .translate-middle-sm {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-sm-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-sm-y {
    transform: translateY(-50%) !important;
  }
  .border-sm {
    border: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-top-sm {
    border-top: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-end-sm {
    border-right: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-end-sm-0 {
    border-right: 0 !important;
  }
  .border-bottom-sm {
    border-bottom: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-start-sm {
    border-left: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-start-sm-0 {
    border-left: 0 !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-1 {
    width: 1px !important;
  }
  .w-sm-2 {
    width: 2px !important;
  }
  .w-sm-3 {
    width: 3px !important;
  }
  .w-sm-33 {
    width: 33.33% !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .translate-middle-md {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-md-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-md-y {
    transform: translateY(-50%) !important;
  }
  .border-md {
    border: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-top-md {
    border-top: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-end-md {
    border-right: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-end-md-0 {
    border-right: 0 !important;
  }
  .border-bottom-md {
    border-bottom: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-start-md {
    border-left: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-start-md-0 {
    border-left: 0 !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-1 {
    width: 1px !important;
  }
  .w-md-2 {
    width: 2px !important;
  }
  .w-md-3 {
    width: 3px !important;
  }
  .w-md-33 {
    width: 33.33% !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .translate-middle-lg {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-lg-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-lg-y {
    transform: translateY(-50%) !important;
  }
  .border-lg {
    border: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-top-lg {
    border-top: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-end-lg {
    border-right: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-end-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-start-lg {
    border-left: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-start-lg-0 {
    border-left: 0 !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-1 {
    width: 1px !important;
  }
  .w-lg-2 {
    width: 2px !important;
  }
  .w-lg-3 {
    width: 3px !important;
  }
  .w-lg-33 {
    width: 33.33% !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .translate-middle-xl {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-xl-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-xl-y {
    transform: translateY(-50%) !important;
  }
  .border-xl {
    border: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-top-xl {
    border-top: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-end-xl {
    border-right: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-end-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xl {
    border-left: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-start-xl-0 {
    border-left: 0 !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-1 {
    width: 1px !important;
  }
  .w-xl-2 {
    width: 2px !important;
  }
  .w-xl-3 {
    width: 3px !important;
  }
  .w-xl-33 {
    width: 33.33% !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .translate-middle-xxl {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-xxl-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-xxl-y {
    transform: translateY(-50%) !important;
  }
  .border-xxl {
    border: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-top-xxl {
    border-top: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-end-xxl {
    border-right: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-end-xxl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xxl {
    border-bottom: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xxl {
    border-left: var(--si-border-width) var(--si-border-style) var(--si-border-color) !important;
  }
  .border-start-xxl-0 {
    border-left: 0 !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-1 {
    width: 1px !important;
  }
  .w-xxl-2 {
    width: 2px !important;
  }
  .w-xxl-3 {
    width: 3px !important;
  }
  .w-xxl-33 {
    width: 33.33% !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.page-wrapper {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

:root {
  --si-user-selection-color: rgba(var(--si-primary-rgb), 0.22);
  --si-heading-link-color: #33354d;
  --si-heading-link-hover-color: #4c82f7;
}

a {
  transition: color 0.2s ease-in-out;
}
a:focus {
  outline: none;
}

a[class^=bg-]:not(.btn),
a[class*=bg-]:not(.btn) {
  transition: background-color 0.25s ease;
}

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

svg {
  max-width: 100%;
}

.dark-mode-img {
  display: none;
}

iframe {
  width: 100%;
}

::-moz-selection {
  background: var(--si-user-selection-color);
}

::selection {
  background: var(--si-user-selection-color);
}

::-moz-selection {
  background: var(--si-user-selection-color);
}

b,
strong {
  font-weight: 800;
}

small, .small {
  font-weight: inherit;
}

button:focus {
  outline: none;
}

@media (min-width: 992px) {
  .docs-container {
    padding-left: 21rem;
  }
}
@media (min-width: 1400px) {
  .docs-container {
    padding-right: 20rem;
  }
}

h4, .h4, .fs-4,
h5, .h5, .fs-5,
h6, .h6, .fs-6 {
  line-height: 1.4;
}

h1 > a, .h1 > a, h2 > a, .h2 > a, h3 > a, .h3 > a,
h4 > a, .h4 > a, h5 > a, .h5 > a, h6 > a, .h6 > a {
  color: var(--si-heading-link-color);
  text-decoration: none;
}
h1 > a:hover, .h1 > a:hover, h2 > a:hover, .h2 > a:hover, h3 > a:hover, .h3 > a:hover,
h4 > a:hover, .h4 > a:hover, h5 > a:hover, .h5 > a:hover, h6 > a:hover, .h6 > a:hover {
  color: var(--si-heading-link-hover-color);
}

dt {
  color: var(--si-heading-color);
}

body .dark-mode p, body .dark-mode ul, body .dark-mode ol, body .dark-mode span, body .dark-mode dd {
  color: var(--si-body-color);
}

:not(pre) > code[class*=language-],
pre[class*=language-],
pre {
  --si-pre-border-width: var(--si-border-width);
  --si-pre-border-color: var(--si-border-color);
  --si-pre-bg: #fff;
  --si-pre-font-size: 0.875em;
  --si-pre-line-numbers-border-width: 1px;
  --si-pre-line-numbers-border-color: #e2e5f1;
  --si-pre-line-numbers-color: #a2a6bc;
  --si-pre-tag-color: #4e54c8;
  --si-pre-comment-color: #a2a6bc;
  --si-pre-attr-name-color: #4091e3;
  --si-pre-attr-value-color: #e3116c;
  --si-pre-class-name-color: #36acaa;
  max-width: 100%;
  margin: 0;
  padding: 1.5rem 1.5rem;
  background-color: var(--si-pre-bg);
  border: var(--si-pre-border-width) solid var(--si-pre-border-color);
  border-radius: 0.5rem;
  font-size: var(--si-pre-font-size);
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03);
}

.code-toolbar :not(pre) > code[class*=language-],
.code-toolbar pre[class*=language-] {
  padding-top: 2.25rem;
}

code[class*=language-],
pre[class*=language-],
pre {
  --si-pre-color: #33354d;
  color: var(--si-pre-color);
  text-shadow: none;
}

.line-numbers .line-numbers-rows {
  border-right: var(--si-pre-line-numbers-border-width) solid var(--si-pre-line-numbers-border-color);
}
.line-numbers .line-numbers-rows > span::before {
  color: var(--si-pre-line-numbers-color);
}

div.code-toolbar > .toolbar {
  opacity: 1;
}
div.code-toolbar > .toolbar > .toolbar-item > a,
div.code-toolbar > .toolbar > .toolbar-item > button,
div.code-toolbar > .toolbar .toolbar-item > span {
  display: inline-block;
  margin: 0.375rem 0.5rem;
  padding: 0.25rem 0.75rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border-radius: 0.25rem;
  border: 1px solid rgba(76, 130, 247, 0.35);
  background-color: transparent;
  color: #4c82f7 !important;
  font-size: 0.75rem;
  font-weight: 600;
  box-shadow: none;
}
div.code-toolbar > .toolbar > .toolbar-item > a:focus,
div.code-toolbar > .toolbar > .toolbar-item > button:focus,
div.code-toolbar > .toolbar .toolbar-item > span:focus {
  outline: none;
  box-shadow: none;
}
div.code-toolbar > .toolbar > .toolbar-item > a:hover,
div.code-toolbar > .toolbar > .toolbar-item > button:hover,
div.code-toolbar > .toolbar .toolbar-item > span:hover {
  background-color: #4c82f7;
  color: #fff !important;
}

.token.boolean, .token.constant, .token.deleted, .token.number,
.token.property, .token.symbol, .token.tag {
  color: var(--si-pre-tag-color);
}

.token.cdata, .token.comment, .token.doctype, .token.prolog {
  color: var(--si-pre-comment-color);
}

.token.attr-name, .token.builtin, .token.char,
.token.inserted, .token.selector {
  color: var(--si-pre-attr-name-color);
}

.token.atrule, .token.attr-value,
.token.keyword, .token.string {
  color: var(--si-pre-attr-value-color);
}

.language-css .token.string, .style .token.string,
.token.entity, .token.operator, .token.url,
.token.class-name, .token.function {
  color: var(--si-pre-class-name-color);
  background: none;
}

.table {
  --si-table-th-color: #000000;
  --si-table-dark-color: rgba(255, 255, 255, 0.7);
}
.table:not(.table-dark) thead:not(.thead-dark) th,
.table:not(.table-dark) tbody th {
  color: var(--si-table-th-color);
}
.table.table-dark td {
  color: var(--si-table-dark-color);
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #b9c0dd;
}

.table.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}

.table > :not(:first-child) {
  border-top: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
}

.form-select:focus {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.2);
}

.form-control:disabled,
.form-select:disabled {
  cursor: not-allowed;
  box-shadow: none !important;
}

.form-control[readonly],
.form-control:disabled {
  box-shadow: none !important;
}
.form-control[readonly]::-moz-placeholder, .form-control:disabled::-moz-placeholder {
  color: #9397ad;
}
.form-control[readonly]::placeholder,
.form-control:disabled::placeholder {
  color: #9397ad;
}

label {
  font-size: 0.875rem;
}

.form-floating > label {
  font-weight: 600;
}

.input-group-text .form-check-input {
  margin-top: 0;
}

.form-switch {
  margin-bottom: 0.5rem;
}
.form-switch .form-check-input {
  height: 1.5rem;
  margin-top: unset;
  border: unset;
  background-color: #b4b7c9;
  filter: none;
}
.form-switch .form-check-input:checked {
  background-color: #4c82f7;
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9);
}
.form-switch .form-check-input:disabled {
  box-shadow: none;
}
.form-switch.mode-switch, .form-switch.price-switch {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
}
.form-switch.mode-switch .form-check-label, .form-switch.price-switch .form-check-label {
  transition: color 0.15s ease-in-out;
  font-size: 1rem;
  font-weight: 600;
}
.form-switch.mode-switch .form-check-label:first-of-type, .form-switch.price-switch .form-check-label:first-of-type {
  order: 1;
  margin-right: 0.75rem;
  color: #000000;
}
.form-switch.mode-switch .form-check-label:last-of-type, .form-switch.price-switch .form-check-label:last-of-type {
  order: 3;
  margin-left: 0.75rem;
}
.form-switch.mode-switch .form-check-input, .form-switch.price-switch .form-check-input {
  order: 2;
  margin-left: 0;
  background-color: #4c82f7;
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9);
}
.form-switch.mode-switch .form-check-input:checked ~ .form-check-label:first-of-type, .form-switch.price-switch .form-check-input:checked ~ .form-check-label:first-of-type {
  color: #565973;
}
.form-switch.mode-switch .form-check-input:checked ~ .form-check-label:last-of-type, .form-switch.price-switch .form-check-input:checked ~ .form-check-label:last-of-type {
  color: #000000;
}
.form-switch.mode-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 26'%3e%3cpath d='M4 22.1679C7.5318 20.6248 9.99998 17.1006 9.99998 13C9.99998 8.89937 7.5318 5.37521 4 3.8321C5.22493 3.2969 6.5778 3 8.00002 3C13.5229 3 18 7.47715 18 13C18 18.5228 13.5229 23 8.00002 23C6.5778 23 5.22493 22.7031 4 22.1679Z' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-switch-success .form-check-input:checked {
  background-color: #22c55e;
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(34, 197, 94, 0.9);
}

.password-toggle {
  position: relative;
}
.password-toggle .form-control {
  padding-right: 3rem;
}

.password-toggle-btn {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  margin-bottom: 0;
  padding: 0.5rem;
  transform: translateY(-50%);
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
}
.password-toggle-btn .password-toggle-indicator {
  transition: color 0.2s ease-in-out;
  color: #9397ad;
  font-family: "boxicons";
  font-size: 1.25em;
  font-style: normal;
}
.password-toggle-btn .password-toggle-indicator::before {
  content: "\ec0d";
}
.password-toggle-btn .password-toggle-indicator:hover {
  color: #33354d;
}
.password-toggle-btn .password-toggle-check {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.password-toggle-btn .password-toggle-check:checked ~ .password-toggle-indicator::before {
  content: "\eb0e";
}

.valid-tooltip {
  position: static;
  background-color: rgba(34, 197, 94, 0.12);
  color: #22c55e;
}

.invalid-tooltip {
  position: static;
  background-color: rgba(239, 68, 68, 0.12);
  color: #ef4444;
}

.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-tooltip,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-tooltip {
  display: table;
}

.was-validated :valid:not(:required):not(.btn),
.is-valid:not(:required):not(.btn) {
  border-color: #d4d7e5 !important;
  background-image: none;
}
.was-validated :valid:not(:required):not(.btn):focus,
.is-valid:not(:required):not(.btn):focus {
  border-color: rgba(76, 130, 247, 0.35) !important;
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.2);
}

.was-validated .password-toggle .form-control:invalid,
.was-validated .password-toggle .form-control.is-invalid,
.was-validated .password-toggle .form-control:valid,
.was-validated .password-toggle .form-control.is-valid {
  padding-right: 4rem;
}
.was-validated .password-toggle .form-control:invalid + .password-toggle-btn,
.was-validated .password-toggle .form-control.is-invalid + .password-toggle-btn,
.was-validated .password-toggle .form-control:valid + .password-toggle-btn,
.was-validated .password-toggle .form-control.is-valid + .password-toggle-btn {
  right: 2.1rem;
}

.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-control:valid {
  z-index: 4;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #4c82f7;
  border-color: #4c82f7;
  box-shadow: none;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: transparent;
  border-color: 2px solid #b4b7c9;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.was-validated .form-check-input.is-valid ~ .form-check-label {
  color: inherit;
}

.range-slider {
  --si-range-slider-hight: 0.1875rem;
  --si-range-slider-bg: #e5e8f3;
  --si-range-slider-connect-bg: #4c82f7;
  --si-range-slider-handle-size: 1.375rem;
  --si-range-slider-handle-bg: #fff;
  --si-range-slider-handle-border-radius: 50%;
  --si-range-slider-handle-shadow: 0 0.125rem 0.5625rem -0.125rem rgba(0, 0, 0, 0.25);
  --si-range-slider-pips-color: var(--si-body-color);
  --si-range-slider-pips-font-size: 0.8125rem;
  --si-range-slider-pips-border-width: var(--si-border-width);
  --si-range-slider-pips-border-color: #cdd2e7;
  --si-range-slider-tooltip-padding-y: 0.25rem;
  --si-range-slider-tooltip-padding-x: 0.5rem;
  --si-range-slider-tooltip-bg: #1f202e;
  --si-range-slider-tooltip-color: #fff;
  --si-range-slider-tooltip-font-size: 0.75rem;
  --si-range-slider-tooltip-border-radius: 0.25rem;
}

.range-slider-ui {
  height: var(--si-range-slider-hight);
  margin: 0;
  margin-top: 3.25rem;
  margin-right: 0;
  margin-bottom: 1.75rem;
  margin-left: 0;
  border: 0;
  background-color: var(--si-range-slider-bg);
  box-shadow: none;
}
[data-pips] .range-slider-ui {
  margin-bottom: 3.5rem;
}
.range-slider-ui .noUi-connect {
  background-color: var(--si-range-slider-connect-bg);
}
.range-slider-ui .noUi-handle {
  top: 50%;
  width: var(--si-range-slider-handle-size);
  height: var(--si-range-slider-handle-size);
  margin-top: calc(var(--si-range-slider-handle-size) * -0.5);
  border: 0;
  border-radius: var(--si-range-slider-handle-border-radius);
  background-color: var(--si-range-slider-handle-bg);
  box-shadow: var(--si-range-slider-handle-shadow);
}
.range-slider-ui .noUi-handle::before, .range-slider-ui .noUi-handle::after {
  display: none;
}
.range-slider-ui .noUi-handle:focus {
  outline: none;
}
.range-slider-ui .noUi-marker-normal {
  display: none;
}
.range-slider-ui .noUi-marker-horizontal.noUi-marker {
  width: var(--si-range-slider-pips-border-width);
  background-color: var(--si-range-slider-pips-border-color);
}
.range-slider-ui .noUi-marker-horizontal.noUi-marker-large {
  height: 0.75rem;
}
.range-slider-ui .noUi-value {
  padding-top: 0.125rem;
  color: var(--si-range-slider-pips-color);
  font-size: var(--si-range-slider-pips-font-size);
}
.range-slider-ui .noUi-tooltip {
  padding: var(--si-range-slider-tooltip-padding-y) var(--si-range-slider-tooltip-padding-x);
  border: 0;
  background-color: var(--si-range-slider-tooltip-bg);
  color: var(--si-range-slider-tooltip-color);
  font-size: var(--si-range-slider-tooltip-font-size);
  line-height: 1.2;
  border-radius: var(--si-range-slider-tooltip-border-radius);
}

html:not([dir=rtl]) .range-slider-ui.noUi-horizontal .noUi-handle {
  right: calc(var(--si-range-slider-handle-size) * -0.5);
}

.subscription-status {
  display: table;
  border-radius: 0.25rem;
  font-size: 0.8125rem;
}
.subscription-status.status-success, .subscription-status.status-error {
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
}
.subscription-status.status-success {
  background-color: rgba(34, 197, 94, 0.1);
  color: #1a9a49;
}
.subscription-status.status-error {
  background-color: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

.credit-card-icon {
  width: 40px;
  height: 26px;
  background-image: url(../img/credit-cards.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
}
.credit-card-icon.amex {
  background-position: 0 -52px !important;
}
.credit-card-icon.diners {
  background-position: 0 -78px !important;
}
.credit-card-icon.discover {
  background-position: 0 -104px !important;
}
.credit-card-icon.jcb {
  background-position: 0 -130px !important;
}
.credit-card-icon.mastercard {
  background-position: 0 -156px !important;
}
.credit-card-icon.visa {
  background-position: 0 -182px !important;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn:not([class^=btn-outline-]):not([class*=" btn-outline-"]):not(.btn-secondary):not(.btn-light):not(.btn-link) {
  --si-btn-color: #fff;
}
.btn:hover, .btn:disabled, .btn.disabled {
  box-shadow: none !important;
}

.btn-primary {
  --si-btn-hover-color: #fff;
  --si-btn-active-color: #fff;
  --si-btn-hover-bg: #2768f5;
  --si-btn-active-bg: #2768f5;
  --si-btn-hover-border-color: #2768f5;
  --si-btn-active-border-color: #2768f5;
  --si-btn-disabled-color: #fff;
}

.btn-secondary {
  --si-btn-hover-color: #fff;
  --si-btn-active-color: #fff;
  --si-btn-hover-bg: #cfd8f6;
  --si-btn-active-bg: #cfd8f6;
  --si-btn-hover-border-color: #cfd8f6;
  --si-btn-active-border-color: #cfd8f6;
  --si-btn-disabled-color: #fff;
}

.btn-success {
  --si-btn-hover-color: #fff;
  --si-btn-active-color: #fff;
  --si-btn-hover-bg: #1ca44e;
  --si-btn-active-bg: #1ca44e;
  --si-btn-hover-border-color: #1ca44e;
  --si-btn-active-border-color: #1ca44e;
  --si-btn-disabled-color: #fff;
}

.btn-info {
  --si-btn-hover-color: #fff;
  --si-btn-active-color: #fff;
  --si-btn-hover-bg: #2768f5;
  --si-btn-active-bg: #2768f5;
  --si-btn-hover-border-color: #2768f5;
  --si-btn-active-border-color: #2768f5;
  --si-btn-disabled-color: #fff;
}

.btn-warning {
  --si-btn-hover-color: #fff;
  --si-btn-active-color: #fff;
  --si-btn-hover-bg: #e1a200;
  --si-btn-active-bg: #e1a200;
  --si-btn-hover-border-color: #e1a200;
  --si-btn-active-border-color: #e1a200;
  --si-btn-disabled-color: #fff;
}

.btn-danger {
  --si-btn-hover-color: #fff;
  --si-btn-active-color: #fff;
  --si-btn-hover-bg: #ec2121;
  --si-btn-active-bg: #ec2121;
  --si-btn-hover-border-color: #ec2121;
  --si-btn-active-border-color: #ec2121;
  --si-btn-disabled-color: #fff;
}

.btn-light {
  --si-btn-hover-color: #fff;
  --si-btn-active-color: #fff;
  --si-btn-hover-bg: #ececec;
  --si-btn-active-bg: #ececec;
  --si-btn-hover-border-color: #ececec;
  --si-btn-active-border-color: #ececec;
  --si-btn-disabled-color: #fff;
}

.btn-dark {
  --si-btn-hover-color: #fff;
  --si-btn-active-color: #fff;
  --si-btn-hover-bg: black;
  --si-btn-active-bg: black;
  --si-btn-hover-border-color: black;
  --si-btn-active-border-color: black;
  --si-btn-disabled-color: #fff;
}

.btn-secondary,
.btn-light {
  --si-btn-color: #33354d;
  --si-btn-hover-color: #000000;
  --si-btn-active-color: #000000;
  --si-btn-disabled-color: #565973;
}

[class^=btn-outline-],
[class*=" btn-outline-"] {
  --si-btn-hover-color: #fff;
  --si-btn-active-color: #fff;
}

.btn-outline-primary {
  --si-btn-border-color: rgba(76, 130, 247, 0.35);
  --si-btn-disabled-border-color: rgba(76, 130, 247, 0.35);
}

.btn-outline-success {
  --si-btn-border-color: rgba(34, 197, 94, 0.35);
  --si-btn-disabled-border-color: rgba(34, 197, 94, 0.35);
}

.btn-outline-info {
  --si-btn-border-color: rgba(76, 130, 247, 0.35);
  --si-btn-disabled-border-color: rgba(76, 130, 247, 0.35);
}

.btn-outline-warning {
  --si-btn-border-color: rgba(255, 186, 8, 0.35);
  --si-btn-disabled-border-color: rgba(255, 186, 8, 0.35);
}

.btn-outline-danger {
  --si-btn-border-color: rgba(239, 68, 68, 0.35);
  --si-btn-disabled-border-color: rgba(239, 68, 68, 0.35);
}

.btn-outline-light {
  --si-btn-border-color: rgba(255, 255, 255, 0.14);
  --si-btn-disabled-border-color: rgba(255, 255, 255, 0.14);
}

.btn-outline-dark {
  --si-btn-border-color: rgba(11, 15, 25, 0.35);
  --si-btn-disabled-border-color: rgba(11, 15, 25, 0.35);
}

.btn-outline-white {
  --si-btn-border-color: rgba(255, 255, 255, 0.35);
  --si-btn-disabled-border-color: rgba(255, 255, 255, 0.35);
}

.btn-outline-secondary {
  --si-btn-color: #33354d;
  --si-btn-hover-color: #000000;
  --si-btn-active-color: #000000;
  --si-btn-hover-bg: #e2e5f1;
  --si-btn-active-bg: #e2e5f1;
  --si-btn-border-color: #d4d7e5;
  --si-btn-hover-border-color: #e2e5f1;
  --si-btn-active-border-color: #e2e5f1;
  --si-btn-disabled-color: #565973;
  --si-btn-disabled-border-color: #e2e5f1;
}

.btn-outline-light {
  --si-btn-border-color: rgba(255, 255, 255, .25);
  --si-btn-hover-color: #33354d;
  --si-btn-active-color: #33354d;
  --si-btn-hover-bg: #fff;
  --si-btn-active-bg: #fff;
}

.btn-link {
  --si-btn-font-weight: 600;
  text-decoration: none;
}

.btn-icon {
  --si-btn-size: 2.75rem;
  --si-btn-icon-size: 1.25rem;
  flex-shrink: 0;
  width: var(--si-btn-size);
  height: var(--si-btn-size);
  padding: 0;
}
.btn-icon > i {
  font-size: var(--si-btn-icon-size);
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  --si-btn-size: 2.25rem;
  --si-btn-icon-size: 1.125rem;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  --si-btn-size: 3.25rem;
  --si-btn-icon-size: 1.4375rem;
}
.btn-icon.btn-xl {
  --si-btn-size: 4.5rem;
  --si-btn-icon-size: 1.75rem;
}

.btn-video {
  --si-btn-video-bg: #fff;
  --si-btn-video-hover-bg: #4c82f7;
  --si-btn-video-color: #33354d;
  --si-btn-video-white-color: #33354d;
  --si-btn-video-hover-color: #fff;
  --si-btn-video-border-radius: 50%;
  --si-btn-video-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03);
  --si-btn-video-hover-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9);
  padding-left: 0.125rem;
  border: 0;
  border-radius: 50%;
  background-color: var(--si-btn-video-bg);
  color: var(--si-btn-video-color) !important;
  box-shadow: var(--si-btn-video-shadow);
}
.btn-video:focus {
  box-shadow: var(--si-btn-video-shadow);
}
.btn-video.bg-white {
  color: var(--si-btn-video-white-color) !important;
}
.btn-video:hover {
  background-color: var(--si-btn-video-hover-bg) !important;
  color: var(--si-btn-video-hover-color) !important;
  box-shadow: var(--si-btn-video-hover-shadow) !important;
}

.btn-group .btn {
  --si-btn-padding-x: 1.3125rem;
}
.btn-group .btn.dropdown-toggle-split {
  --si-btn-padding-x: 0.875rem;
  padding-right: var(--si-btn-padding-x);
  padding-left: var(--si-btn-padding-x);
}

.btn-group-lg .btn,
.btn-group .btn-lg {
  --si-btn-padding-x: 1.5rem;
}
.btn-group-lg .btn.dropdown-toggle-split,
.btn-group .btn-lg.dropdown-toggle-split {
  --si-btn-padding-x: 1rem;
  padding-right: var(--si-btn-padding-x);
  padding-left: var(--si-btn-padding-x);
}

.btn-group-sm .btn,
.btn-group .btn-sm {
  --si-btn-padding-x: 0.9375rem;
}
.btn-group-sm .btn.dropdown-toggle-split,
.btn-group .btn-sm.dropdown-toggle-split {
  --si-btn-padding-x: 0.625rem;
  padding-right: var(--si-btn-padding-x);
  padding-left: var(--si-btn-padding-x);
}

.btn-close {
  transition: opacity 0.25s ease-in-out;
}

.btn-scroll-top {
  --si-btn-scroll-top-size: 2.75rem;
  --si-btn-scroll-top-border-radius: 50%;
  --si-btn-scroll-top-color: #fff;
  --si-btn-scroll-top-hover-color: #fff;
  --si-btn-scroll-top-bg: rgba(11, 15, 25, 0.2);
  --si-btn-scroll-top-hover-bg: rgba(11, 15, 25, 0.4);
  --si-btn-scroll-top-icon-size: 1.5rem;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1.25rem;
  bottom: -4.125rem;
  width: var(--si-btn-scroll-top-size);
  height: var(--si-btn-scroll-top-size);
  transition: bottom 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.3s, background-color 0.25s ease-in-out;
  border-radius: var(--si-btn-scroll-top-border-radius);
  background-color: var(--si-btn-scroll-top-bg);
  color: var(--si-btn-scroll-top-color);
  text-decoration: none;
  opacity: 0;
  z-index: 1030;
}
.btn-scroll-top > .btn-scroll-top-icon {
  font-size: var(--si-btn-scroll-top-icon-size);
  font-weight: bold;
}
.btn-scroll-top .btn-scroll-top-tooltip {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
.btn-scroll-top:hover {
  background-color: var(--si-btn-scroll-top-hover-bg);
  color: var(--si-btn-scroll-top-hover-color);
}
.btn-scroll-top:hover .btn-scroll-top-tooltip {
  visibility: visible;
  opacity: 1;
}
.btn-scroll-top.show {
  bottom: 1.25rem;
  opacity: 1;
}
@media (max-width: 499.98px) {
  .btn-scroll-top {
    width: calc(var(--si-btn-scroll-top-size) * 0.8);
    height: calc(var(--si-btn-scroll-top-size) * 0.8);
    right: 1rem;
  }
  .btn-scroll-top.show {
    bottom: 1rem;
  }
}

.btn-airbnb:hover, .btn-airbnb:focus, .btn-airbnb:active {
  border-color: #fd5c63 !important;
  background-color: #fd5c63 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(253, 92, 99, 0.8) !important;
}

.btn-behance:hover, .btn-behance:focus, .btn-behance:active {
  border-color: #1769ff !important;
  background-color: #1769ff !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(23, 105, 255, 0.8) !important;
}

.btn-blogger:hover, .btn-blogger:focus, .btn-blogger:active {
  border-color: #f57d00 !important;
  background-color: #f57d00 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(245, 125, 0, 0.8) !important;
}

.btn-codepen:hover, .btn-codepen:focus, .btn-codepen:active {
  border-color: #0ebeff !important;
  background-color: #0ebeff !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(14, 190, 255, 0.8) !important;
}

.btn-discord:hover, .btn-discord:focus, .btn-discord:active {
  border-color: #7289da !important;
  background-color: #7289da !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(114, 137, 218, 0.8) !important;
}

.btn-dribbble:hover, .btn-dribbble:focus, .btn-dribbble:active {
  border-color: #ea4c89 !important;
  background-color: #ea4c89 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(234, 76, 137, 0.8) !important;
}

.btn-dropbox:hover, .btn-dropbox:focus, .btn-dropbox:active {
  border-color: #007ee5 !important;
  background-color: #007ee5 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(0, 126, 229, 0.8) !important;
}

.btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active {
  border-color: #1877f2 !important;
  background-color: #1877f2 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(24, 119, 242, 0.8) !important;
}

.btn-flickr:hover, .btn-flickr:focus, .btn-flickr:active {
  border-color: #0063dc !important;
  background-color: #0063dc !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(0, 99, 220, 0.8) !important;
}

.btn-foursquare:hover, .btn-foursquare:focus, .btn-foursquare:active {
  border-color: #f94877 !important;
  background-color: #f94877 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(249, 72, 119, 0.8) !important;
}

.btn-gitlab:hover, .btn-gitlab:focus, .btn-gitlab:active {
  border-color: #fc6d26 !important;
  background-color: #fc6d26 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(252, 109, 38, 0.8) !important;
}

.btn-github:hover, .btn-github:focus, .btn-github:active {
  border-color: #4078c0 !important;
  background-color: #4078c0 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(64, 120, 192, 0.8) !important;
}

.btn-google:hover, .btn-google:focus, .btn-google:active {
  border-color: #ea4335 !important;
  background-color: #ea4335 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(234, 67, 53, 0.8) !important;
}

.btn-instagram:hover, .btn-instagram:focus, .btn-instagram:active {
  border-color: #405de6 !important;
  background-color: #405de6 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(64, 93, 230, 0.8) !important;
}

.btn-kickstarter:hover, .btn-kickstarter:focus, .btn-kickstarter:active {
  border-color: #2bde73 !important;
  background-color: #2bde73 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(43, 222, 115, 0.8) !important;
}

.btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active {
  border-color: #0077b5 !important;
  background-color: #0077b5 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(0, 119, 181, 0.8) !important;
}

.btn-medium:hover, .btn-medium:focus, .btn-medium:active {
  border-color: #00ab6c !important;
  background-color: #00ab6c !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(0, 171, 108, 0.8) !important;
}

.btn-messenger:hover, .btn-messenger:focus, .btn-messenger:active {
  border-color: #0084ff !important;
  background-color: #0084ff !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(0, 132, 255, 0.8) !important;
}

.btn-ok:hover, .btn-ok:focus, .btn-ok:active {
  border-color: #ed812b !important;
  background-color: #ed812b !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(237, 129, 43, 0.8) !important;
}

.btn-patreon:hover, .btn-patreon:focus, .btn-patreon:active {
  border-color: #f96854 !important;
  background-color: #f96854 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(249, 104, 84, 0.8) !important;
}

.btn-pinterest:hover, .btn-pinterest:focus, .btn-pinterest:active {
  border-color: #e60023 !important;
  background-color: #e60023 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(230, 0, 35, 0.8) !important;
}

.btn-product-hunt:hover, .btn-product-hunt:focus, .btn-product-hunt:active {
  border-color: #da552f !important;
  background-color: #da552f !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(218, 85, 47, 0.8) !important;
}

.btn-reddit:hover, .btn-reddit:focus, .btn-reddit:active {
  border-color: #ff4500 !important;
  background-color: #ff4500 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(255, 69, 0, 0.8) !important;
}

.btn-skype:hover, .btn-skype:focus, .btn-skype:active {
  border-color: #00aff0 !important;
  background-color: #00aff0 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(0, 175, 240, 0.8) !important;
}

.btn-slack:hover, .btn-slack:focus, .btn-slack:active {
  border-color: #611f69 !important;
  background-color: #611f69 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(97, 31, 105, 0.8) !important;
}

.btn-snapchat:hover, .btn-snapchat:focus, .btn-snapchat:active {
  border-color: #fffc00 !important;
  background-color: #fffc00 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(255, 252, 0, 0.8) !important;
}

.btn-stack-overflow:hover, .btn-stack-overflow:focus, .btn-stack-overflow:active {
  border-color: #f48024 !important;
  background-color: #f48024 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(244, 128, 36, 0.8) !important;
}

.btn-steam:hover, .btn-steam:focus, .btn-steam:active {
  border-color: #00adee !important;
  background-color: #00adee !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(0, 173, 238, 0.8) !important;
}

.btn-telegram:hover, .btn-telegram:focus, .btn-telegram:active {
  border-color: #0088cc !important;
  background-color: #0088cc !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(0, 136, 204, 0.8) !important;
}

.btn-tiktok:hover, .btn-tiktok:focus, .btn-tiktok:active {
  border-color: #fe2c55 !important;
  background-color: #fe2c55 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(254, 44, 85, 0.8) !important;
}

.btn-trip-advisor:hover, .btn-trip-advisor:focus, .btn-trip-advisor:active {
  border-color: #00af87 !important;
  background-color: #00af87 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(0, 175, 135, 0.8) !important;
}

.btn-tumblr:hover, .btn-tumblr:focus, .btn-tumblr:active {
  border-color: #35465c !important;
  background-color: #35465c !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(53, 70, 92, 0.8) !important;
}

.btn-twitch:hover, .btn-twitch:focus, .btn-twitch:active {
  border-color: #9146ff !important;
  background-color: #9146ff !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(145, 70, 255, 0.8) !important;
}

.btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active {
  border-color: #1da1f2 !important;
  background-color: #1da1f2 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(29, 161, 242, 0.8) !important;
}

.btn-vimeo:hover, .btn-vimeo:focus, .btn-vimeo:active {
  border-color: #1ab7ea !important;
  background-color: #1ab7ea !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(26, 183, 234, 0.8) !important;
}

.btn-vk:hover, .btn-vk:focus, .btn-vk:active {
  border-color: #45668e !important;
  background-color: #45668e !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(69, 102, 142, 0.8) !important;
}

.btn-whatsapp:hover, .btn-whatsapp:focus, .btn-whatsapp:active {
  border-color: #128c7e !important;
  background-color: #128c7e !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(18, 140, 126, 0.8) !important;
}

.btn-youtube:hover, .btn-youtube:focus, .btn-youtube:active {
  border-color: #ff0000 !important;
  background-color: #ff0000 !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(255, 0, 0, 0.8) !important;
}

.btn-zoom:hover, .btn-zoom:focus, .btn-zoom:active {
  border-color: #2d8cff !important;
  background-color: #2d8cff !important;
  color: #fff !important;
  box-shadow: 0 0.375rem 1.125rem -0.375rem rgba(45, 140, 255, 0.8) !important;
}

.btn-snapchat:hover, .btn-snapchat:focus, .btn-snapchat:active {
  color: #33354d !important;
}

.dropdown-toggle::before, .dropdown-toggle::after {
  font-family: "boxicons";
  font-size: 1.375em;
  font-weight: normal;
  vertical-align: middle !important;
  border: none !important;
  line-height: 1;
}
.dropdown-toggle::after {
  margin-right: -0.25rem;
  content: "\ea4a";
}
.dropdown-toggle.fs-sm::after {
  margin-top: -0.0625rem !important;
}
.dropdown-toggle.btn:not(.btn-lg)::before, .dropdown-toggle.btn:not(.btn-lg)::after {
  font-size: 1.375em;
}

.dropup .dropdown-toggle::after {
  content: "\ea57";
}

.dropstart .dropdown-toggle::before {
  margin-left: -0.3125rem;
  content: "\ea4d";
}

.dropend .dropdown-toggle::after {
  margin-right: -0.3125rem;
  content: "\ed3b";
  font-size: 1.15em !important;
}

.dropdown-toggle-split::before, .dropdown-toggle-split::after {
  margin: 0;
}

.dropdown-menu {
  --si-dropdown-link-font-weight: 500;
}
.dropdown-menu li:hover > .dropdown-item {
  color: var(--si-dropdown-link-hover-color);
}
.dropdown-menu .active > .dropdown-item {
  color: var(--si-dropdown-link-active-color);
}
.dropdown-menu .active > .dropdown-item,
.dropdown-menu .dropdown-item.active {
  pointer-events: none;
}
.dropdown-menu.w-100 {
  min-width: 100%;
}

.dropdown-menu-dark, .dark-mode .dropdown-menu {
  background: var(--si-dropdown-bg);
}
.dropdown-menu-dark li:hover > .dropdown-item, .dark-mode .dropdown-menu li:hover > .dropdown-item {
  color: var(--si-dropdown-link-hover-color);
}
.dropdown-menu-dark .active > .dropdown-item, .dark-mode .dropdown-menu .active > .dropdown-item {
  color: var(--si-dropdown-link-active-color);
}

.dropdown-item {
  font-weight: var(--si-dropdown-link-font-weight);
}

.dropdown-divider {
  margin-right: var(--si-dropdown-item-padding-x);
  margin-left: var(--si-dropdown-item-padding-x);
}

hr.dropdown-divider {
  border: 0;
  height: var(--si-dropdown-border-width);
  background-color: var(--si-dropdown-divider-bg);
}

.dropdown-menu-dark hr.dropdown-divider, .dark-mode .dropdown-menu hr.dropdown-divider {
  border: 0;
  background-color: var(--si-dropdown-divider-bg);
}

.dropdown-menu.show {
  -webkit-animation: fade-in 0.2s ease-in-out;
  animation: fade-in 0.2s ease-in-out;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.nav-tabs-alt > .nav-item > .dropdown-menu {
  margin-top: -0.125rem !important;
  border-top-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-item {
  margin-bottom: 0;
}

.nav {
  --si-nav-link-active-color: #4c82f7;
}

.nav-link {
  display: flex;
  align-items: center;
}
.nav-link.active {
  color: var(--si-nav-link-active-color);
}
.nav-link.active:not([data-bs-toggle=dropdown]) {
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --si-nav-tabs-link-bg: #eff2fc;
  --si-nav-tabs-link-hover-bg: rgba(76, 130, 247, 0.12);
  --si-nav-tabs-link-disabled-bg: #f3f6ff;
  --si-nav-tabs-link-color: #33354d;
  --si-nav-tabs-link-hover-color: #4c82f7;
  margin-bottom: 0.75rem;
}
.nav-tabs .nav-item {
  margin-bottom: 0.75rem;
}
.nav-tabs .nav-link {
  border-radius: var(--si-nav-tabs-border-radius);
  background-color: var(--si-nav-tabs-link-bg);
  color: var(--si-nav-tabs-link-color);
}
.nav-tabs .nav-link:hover:not(.active), .nav-tabs .nav-link.show:not(.active) {
  background-color: var(--si-nav-tabs-link-hover-bg);
  color: var(--si-nav-tabs-link-hover-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link[disabled] {
  background-color: var(--si-nav-tabs-link-disabled-bg);
}
.nav-tabs .dropdown-menu {
  border-top-left-radius: var(--si-dropdown-border-radius);
  border-top-right-radius: var(--si-dropdown-border-radius);
}

.nav-tabs-alt {
  --si-nav-tabs-alt-border-width: 1px;
  --si-nav-tabs-alt-border-color: #e2e5f1;
  --si-nav-tabs-alt-link-padding-y: 0.8025rem;
  --si-nav-tabs-alt-link-padding-x: 1rem;
  --si-nav-tabs-alt-link-active-border-color: #4c82f7;
  margin-bottom: 1.5rem;
  border-bottom: var(--si-nav-tabs-alt-border-width) solid var(--si-nav-tabs-alt-border-color);
}
.nav-tabs-alt .nav-item {
  margin-bottom: calc(var(--si-nav-tabs-alt-border-width) * -1);
}
.nav-tabs-alt .nav-link {
  padding: var(--si-nav-tabs-alt-link-padding-y) var(--si-nav-tabs-alt-link-padding-x);
  border-bottom: 1px solid transparent;
}
.nav-tabs-alt .nav-link.active {
  border-bottom-color: var(--si-nav-tabs-alt-link-active-border-color);
}

.nav-tabs .nav-item,
.nav-pills .nav-item {
  margin-right: 0.75rem;
}
.nav-tabs .nav-item:last-child,
.nav-pills .nav-item:last-child {
  margin-right: 0;
}
.nav-tabs.justify-content-center .nav-item,
.nav-pills.justify-content-center .nav-item {
  margin-right: 0.375rem;
  margin-left: 0.375rem;
}
.nav-tabs.justify-content-end .nav-item,
.nav-pills.justify-content-end .nav-item {
  margin-right: 0;
  margin-left: 0.75rem;
}
.nav-tabs.flex-column .nav-item,
.nav-pills.flex-column .nav-item {
  margin-right: 0;
  margin-bottom: 0.75rem;
  margin-left: 0;
}
.nav-tabs.flex-column .nav-item:last-child,
.nav-pills.flex-column .nav-item:last-child {
  margin-bottom: 0;
}

.side-nav {
  --si-side-nav-padding-y: 0;
  --si-side-nav-padding-x: 2rem;
  --si-side-nav-border-width: 1px;
  --si-side-nav-border-color: #e2e5f1;
  --si-side-nav-link-padding-y: 0.375rem;
  --si-side-nav-link-padding-x: 2rem;
  --si-side-nav-link-font-size: 0.875rem;
  --si-side-nav-link-active-border-color: #4c82f7;
  padding: var(--si-side-nav-padding-y) var(--si-side-nav-padding-x);
}
.side-nav .nav {
  flex-direction: column;
  margin-right: calc(var(--si-side-nav-padding-x) * -1);
  margin-left: calc(var(--si-side-nav-padding-x) * -1);
}
.side-nav .nav-link {
  padding: var(--si-side-nav-link-padding-y) var(--si-side-nav-link-padding-x);
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  font-size: var(--si-side-nav-link-font-size);
}
.side-nav.side-nav-start {
  border-right: var(--si-side-nav-border-width) solid var(--si-side-nav-border-color);
}
.side-nav.side-nav-start .nav-link {
  margin-right: calc(var(--si-side-nav-border-width) * -1);
  border-right: var(--si-side-nav-border-width) solid transparent;
}
.side-nav.side-nav-end {
  border-left: var(--si-side-nav-border-width) solid var(--si-side-nav-border-color);
}
.side-nav.side-nav-end .nav-link {
  margin-left: calc(var(--si-side-nav-border-width) * -1);
  border-left: var(--si-side-nav-border-width) solid transparent;
}
.side-nav .active > .nav-link,
.side-nav .nav-link.active {
  border-color: var(--si-side-nav-link-active-border-color);
}

.bg-faded-primary-hover {
  transition: background-color 0.2s ease-in-out;
}
.bg-faded-primary-hover:hover {
  background-color: rgba(76, 130, 247, 0.12) !important;
}

.navbar-brand {
  --si-navbar-brand-padding-y: 0.5rem;
  --si-navbar-brand-margin-end: 1rem;
  --si-navbar-brand-font-size: 1.375rem;
  --si-navbar-brand-font-weight: 800;
  display: flex;
  align-items: center;
  padding: calc(var(--si-navbar-brand-padding-y) * 0.5) 0;
  font-weight: var(--si-navbar-brand-font-weight);
}
.navbar-brand > img {
  margin-right: 0.4375rem;
}

.navbar-toggler {
  position: relative;
  width: 1.875rem;
  height: 2.25rem;
}

.navbar-toggler-icon {
  display: block;
  top: 50%;
  margin-top: -0.0625rem;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.navbar-toggler-icon, .navbar-toggler-icon::before, .navbar-toggler-icon::after {
  position: absolute;
  width: 1.375rem;
  height: 0.125rem;
  transition-property: transform 0.15s ease;
  background-color: var(--si-navbar-toggler-color);
}
.navbar-toggler-icon::before, .navbar-toggler-icon::after {
  display: block;
  content: "";
}
.navbar-toggler-icon::before {
  top: -0.4375rem;
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.navbar-toggler-icon::after {
  bottom: -0.4375rem;
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

[aria-expanded=true] .navbar-toggler-icon {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
[aria-expanded=true] .navbar-toggler-icon::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
[aria-expanded=true] .navbar-toggler-icon::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.navbar-nav {
  --si-nav-link-font-weight: 600;
  --si-mega-dropdown-column-width: 14rem;
}

.navbar .dropdown-toggle::after {
  margin-top: 0.0625rem;
  font-size: 1.15em;
}
.navbar .navbar-nav .dropdown-menu {
  border: 0;
  border-left: var(--si-dropdown-border-wdth, 1px) solid var(--si-border-color);
  border-radius: 0;
  background-color: transparent;
  background: none;
  box-shadow: none;
}
.navbar .navbar-nav .dropdown-menu.dropdown-menu-dark, .navbar .navbar-nav .dark-mode .dropdown-menu, .dark-mode .navbar .navbar-nav .dropdown-menu {
  border-color: rgba(255, 255, 255, 0.14);
}
.navbar .navbar-nav .dropdown-menu .dropdown-menu {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.navbar .navbar-nav .dropdown-menu .dropdown-toggle::after {
  margin-top: -0.0625rem;
  margin-right: 0;
  margin-left: 0.125rem;
  font-size: 1em;
}
.navbar .navbar-nav .dropdown-menu .dropdown-divider {
  display: none;
}

@-webkit-keyframes dropdown-show {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes dropdown-show {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@media (min-width: 500px) {
  .navbar-expand-sm .navbar-brand {
    padding: var(--si-navbar-brand-padding-y) 0;
  }
  .navbar-expand-sm .offcanvas {
    background-color: transparent !important;
    box-shadow: none;
  }
  .navbar-expand-sm .offcanvas-header,
.navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .dropdown-divider {
    display: block;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    margin-top: var(--si-dropdown-spacer);
    border: var(--si-dropdown-border-width) solid var(--si-dropdown-border-color);
    border-radius: var(--si-dropdown-border-radius);
    background-color: var(--si-dropdown-bg);
    box-shadow: var(--si-dropdown-box-shadow);
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu.dropdown-menu-dark, .navbar-expand-sm .navbar-nav .dark-mode .dropdown-menu, .dark-mode .navbar-expand-sm .navbar-nav .dropdown-menu {
    background: var(--si-dropdown-bg);
  }
  .navbar-expand-sm .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-sm .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }
  .navbar-expand-sm .dropdown .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .navbar-expand-sm .dropdown .dropdown .dropdown-toggle::after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.25em;
    content: "\ea50";
  }
  .navbar-expand-sm .dropdown .dropdown .dropdown-menu {
    top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
    left: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-expand-sm .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-expand-sm .dropstart > .dropdown-menu {
    top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
    right: 94%;
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-expand-sm .dropdown:hover > .dropdown-menu,
.navbar-expand-sm .dropstart:hover > .dropdown-menu {
    display: block;
    -webkit-animation: dropdown-show 0.2s;
    animation: dropdown-show 0.2s;
  }
  .navbar-expand-sm .mega-dropdown-column {
    width: var(--si-mega-dropdown-column-width);
    padding: 0 0.75rem;
  }
  .navbar-expand-sm .mega-dropdown-column:last-child {
    border-right: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-brand {
    padding: var(--si-navbar-brand-padding-y) 0;
  }
  .navbar-expand-md .offcanvas {
    background-color: transparent !important;
    box-shadow: none;
  }
  .navbar-expand-md .offcanvas-header,
.navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .dropdown-divider {
    display: block;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    margin-top: var(--si-dropdown-spacer);
    border: var(--si-dropdown-border-width) solid var(--si-dropdown-border-color);
    border-radius: var(--si-dropdown-border-radius);
    background-color: var(--si-dropdown-bg);
    box-shadow: var(--si-dropdown-box-shadow);
  }
  .navbar-expand-md .navbar-nav .dropdown-menu.dropdown-menu-dark, .navbar-expand-md .navbar-nav .dark-mode .dropdown-menu, .dark-mode .navbar-expand-md .navbar-nav .dropdown-menu {
    background: var(--si-dropdown-bg);
  }
  .navbar-expand-md .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-md .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }
  .navbar-expand-md .dropdown .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .navbar-expand-md .dropdown .dropdown .dropdown-toggle::after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.25em;
    content: "\ea50";
  }
  .navbar-expand-md .dropdown .dropdown .dropdown-menu {
    top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
    left: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-expand-md .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-expand-md .dropstart > .dropdown-menu {
    top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
    right: 94%;
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-expand-md .dropdown:hover > .dropdown-menu,
.navbar-expand-md .dropstart:hover > .dropdown-menu {
    display: block;
    -webkit-animation: dropdown-show 0.2s;
    animation: dropdown-show 0.2s;
  }
  .navbar-expand-md .mega-dropdown-column {
    width: var(--si-mega-dropdown-column-width);
    padding: 0 0.75rem;
  }
  .navbar-expand-md .mega-dropdown-column:last-child {
    border-right: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-brand {
    padding: var(--si-navbar-brand-padding-y) 0;
  }
  .navbar-expand-lg .offcanvas {
    background-color: transparent !important;
    box-shadow: none;
  }
  .navbar-expand-lg .offcanvas-header,
.navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .dropdown-divider {
    display: block;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: var(--si-dropdown-spacer);
    border: var(--si-dropdown-border-width) solid var(--si-dropdown-border-color);
    border-radius: var(--si-dropdown-border-radius);
    background-color: var(--si-dropdown-bg);
    box-shadow: var(--si-dropdown-box-shadow);
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu.dropdown-menu-dark, .navbar-expand-lg .navbar-nav .dark-mode .dropdown-menu, .dark-mode .navbar-expand-lg .navbar-nav .dropdown-menu {
    background: var(--si-dropdown-bg);
  }
  .navbar-expand-lg .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-lg .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }
  .navbar-expand-lg .dropdown .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .navbar-expand-lg .dropdown .dropdown .dropdown-toggle::after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.25em;
    content: "\ea50";
  }
  .navbar-expand-lg .dropdown .dropdown .dropdown-menu {
    top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
    left: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-expand-lg .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-expand-lg .dropstart > .dropdown-menu {
    top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
    right: 94%;
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-expand-lg .dropdown:hover > .dropdown-menu,
.navbar-expand-lg .dropstart:hover > .dropdown-menu {
    display: block;
    -webkit-animation: dropdown-show 0.2s;
    animation: dropdown-show 0.2s;
  }
  .navbar-expand-lg .mega-dropdown-column {
    width: var(--si-mega-dropdown-column-width);
    padding: 0 0.75rem;
  }
  .navbar-expand-lg .mega-dropdown-column:last-child {
    border-right: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-brand {
    padding: var(--si-navbar-brand-padding-y) 0;
  }
  .navbar-expand-xl .offcanvas {
    background-color: transparent !important;
    box-shadow: none;
  }
  .navbar-expand-xl .offcanvas-header,
.navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .dropdown-divider {
    display: block;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    margin-top: var(--si-dropdown-spacer);
    border: var(--si-dropdown-border-width) solid var(--si-dropdown-border-color);
    border-radius: var(--si-dropdown-border-radius);
    background-color: var(--si-dropdown-bg);
    box-shadow: var(--si-dropdown-box-shadow);
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu.dropdown-menu-dark, .navbar-expand-xl .navbar-nav .dark-mode .dropdown-menu, .dark-mode .navbar-expand-xl .navbar-nav .dropdown-menu {
    background: var(--si-dropdown-bg);
  }
  .navbar-expand-xl .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-xl .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }
  .navbar-expand-xl .dropdown .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .navbar-expand-xl .dropdown .dropdown .dropdown-toggle::after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.25em;
    content: "\ea50";
  }
  .navbar-expand-xl .dropdown .dropdown .dropdown-menu {
    top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
    left: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-expand-xl .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-expand-xl .dropstart > .dropdown-menu {
    top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
    right: 94%;
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-expand-xl .dropdown:hover > .dropdown-menu,
.navbar-expand-xl .dropstart:hover > .dropdown-menu {
    display: block;
    -webkit-animation: dropdown-show 0.2s;
    animation: dropdown-show 0.2s;
  }
  .navbar-expand-xl .mega-dropdown-column {
    width: var(--si-mega-dropdown-column-width);
    padding: 0 0.75rem;
  }
  .navbar-expand-xl .mega-dropdown-column:last-child {
    border-right: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-brand {
    padding: var(--si-navbar-brand-padding-y) 0;
  }
  .navbar-expand-xxl .offcanvas {
    background-color: transparent !important;
    box-shadow: none;
  }
  .navbar-expand-xxl .offcanvas-header,
.navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .dropdown-divider {
    display: block;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    margin-top: var(--si-dropdown-spacer);
    border: var(--si-dropdown-border-width) solid var(--si-dropdown-border-color);
    border-radius: var(--si-dropdown-border-radius);
    background-color: var(--si-dropdown-bg);
    box-shadow: var(--si-dropdown-box-shadow);
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu.dropdown-menu-dark, .navbar-expand-xxl .navbar-nav .dark-mode .dropdown-menu, .dark-mode .navbar-expand-xxl .navbar-nav .dropdown-menu {
    background: var(--si-dropdown-bg);
  }
  .navbar-expand-xxl .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-xxl .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.375rem;
    content: "";
  }
  .navbar-expand-xxl .dropdown .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .navbar-expand-xxl .dropdown .dropdown .dropdown-toggle::after {
    margin-top: 0;
    margin-right: -0.3125rem;
    margin-left: auto;
    font-size: 1.25em;
    content: "\ea50";
  }
  .navbar-expand-xxl .dropdown .dropdown .dropdown-menu {
    top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
    left: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-expand-xxl .dropdown .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-expand-xxl .dropstart > .dropdown-menu {
    top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
    right: 94%;
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-expand-xxl .dropdown:hover > .dropdown-menu,
.navbar-expand-xxl .dropstart:hover > .dropdown-menu {
    display: block;
    -webkit-animation: dropdown-show 0.2s;
    animation: dropdown-show 0.2s;
  }
  .navbar-expand-xxl .mega-dropdown-column {
    width: var(--si-mega-dropdown-column-width);
    padding: 0 0.75rem;
  }
  .navbar-expand-xxl .mega-dropdown-column:last-child {
    border-right: 0;
  }
}
.navbar-expand .navbar-brand {
  padding: var(--si-navbar-brand-padding-y) 0;
}
.navbar-expand .offcanvas {
  background-color: transparent !important;
  box-shadow: none;
}
.navbar-expand .offcanvas-header,
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .dropdown-divider {
  display: block;
}
.navbar-expand .navbar-nav .dropdown-menu {
  margin-top: var(--si-dropdown-spacer);
  border: var(--si-dropdown-border-width) solid var(--si-dropdown-border-color);
  border-radius: var(--si-dropdown-border-radius);
  background-color: var(--si-dropdown-bg);
  box-shadow: var(--si-dropdown-box-shadow);
}
.navbar-expand .navbar-nav .dropdown-menu.dropdown-menu-dark, .navbar-expand .navbar-nav .dark-mode .dropdown-menu, .dark-mode .navbar-expand .navbar-nav .dropdown-menu {
  background: var(--si-dropdown-bg);
}
.navbar-expand .dropdown-menu.show {
  display: none;
}
.navbar-expand .dropdown::before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0.375rem;
  content: "";
}
.navbar-expand .dropdown .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
}
.navbar-expand .dropdown .dropdown .dropdown-toggle::after {
  margin-top: 0;
  margin-right: -0.3125rem;
  margin-left: auto;
  font-size: 1.25em;
  content: "\ea50";
}
.navbar-expand .dropdown .dropdown .dropdown-menu {
  top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
  left: 100%;
  margin-right: 0;
  margin-left: 0;
}
.navbar-expand .dropdown .dropdown-menu-end {
  left: auto;
  right: 0;
}
.navbar-expand .dropstart > .dropdown-menu {
  top: calc((var(--si-dropdown-item-padding-y) + var(--si-dropdown-padding-y)) * -1);
  right: 94%;
  margin-right: 0;
  margin-left: 0;
}
.navbar-expand .dropdown:hover > .dropdown-menu,
.navbar-expand .dropstart:hover > .dropdown-menu {
  display: block;
  -webkit-animation: dropdown-show 0.2s;
  animation: dropdown-show 0.2s;
}
.navbar-expand .mega-dropdown-column {
  width: var(--si-mega-dropdown-column-width);
  padding: 0 0.75rem;
}
.navbar-expand .mega-dropdown-column:last-child {
  border-right: 0;
}

.navbar-stuck {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-animation: navbar-show 0.25s ease-in-out;
  animation: navbar-show 0.25s ease-in-out;
  box-shadow: 0 0.275rem 1.25rem rgba(11, 15, 25, 0.05), 0 0.25rem 0.5625rem rgba(11, 15, 25, 0.03);
  z-index: 1031;
}

.navbar.position-absolute {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1030;
}

@-webkit-keyframes navbar-show {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes navbar-show {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.navbar {
  --si-navbar-stuck-bg: #fff;
  --si-navbar-toggler-color: #33354d;
}
.navbar.navbar-stuck {
  background-color: var(--si-navbar-stuck-bg);
}
.navbar .nav-item:hover > .nav-link:not(.disabled) {
  color: var(--si-navbar-hover-color);
}
.navbar .nav-item.active > .nav-link:not(.disabled) {
  color: var(--si-navbar-active-color);
}
.navbar .dropdown:hover > .nav-link {
  color: var(--si-navbar-hover-color);
}
.navbar.fixed-top {
  z-index: 1031;
}

.navbar-dark {
  --si-navbar-stuck-bg: #000000;
  --si-navbar-toggler-color: rgba(255, 255, 255, 0.85);
}

.card-header,
.card-footer {
  width: calc(100% - var(--si-card-spacer-x) * 2);
  margin: auto;
  padding-right: 0;
  padding-left: 0;
}

.card-header-tabs,
.card-header-pills {
  margin: 0;
}
.card-header-tabs .nav-item,
.card-header-pills .nav-item {
  margin-bottom: 0;
}
.card-header-tabs .nav-link.active,
.card-header-pills .nav-link.active {
  border-color: var(--si-nav-tabs-link-active-border-color);
  background-color: var(--si-nav-tabs-link-active-bg);
}
.card-header-tabs.nav-tabs-alt,
.card-header-pills.nav-tabs-alt {
  margin-top: calc(var(--si-card-cap-padding-y) * -0.625);
  margin-bottom: calc((var(--si-card-cap-padding-y) + 0.0625rem) * -1);
  border-bottom: 0;
}

.card-header-pills .nav-link.active {
  background-color: var(--si-nav-pills-link-active-bg);
}

.card-hover:not(.bg-transparent) {
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.card-hover:not(.bg-transparent):hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03);
}
.card-hover:not(.bg-transparent).shadow-sm:hover {
  box-shadow: 0 0.275rem 1.25rem rgba(11, 15, 25, 0.05), 0 0.25rem 0.5625rem rgba(11, 15, 25, 0.03) !important;
}
.card-hover:not(.bg-transparent).shadow:hover {
  box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(11, 15, 25, 0.1), 0 0.25rem 0.8125rem -0.125rem rgba(11, 15, 25, 0.06) !important;
}
.card-hover:not(.bg-transparent).border-primary:hover {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9) !important;
}

.card-hover {
  --si-card-icon-hover-color: #4c82f7;
}
.card-hover .card-img-overlay {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: 5;
}
.card-hover .card-icon {
  transition: color 0.3s ease-in-out;
}
.card-hover:hover .card-icon {
  color: var(--si-card-icon-hover-color) !important;
}
.card-hover:hover .card-img-overlay {
  opacity: 1;
}

.card-hover-primary {
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.card-hover-primary.shadow-sm:hover, .card-hover-primary.shadow:hover, .card-hover-primary:hover {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9) !important;
}
.card-hover-primary .card-title,
.card-hover-primary .card-body,
.card-hover-primary .text-muted,
.card-hover-primary .text-dark,
.card-hover-primary .text-nav,
.card-hover-primary .text-primary,
.card-hover-primary h1, .card-hover-primary .h1, .card-hover-primary h2, .card-hover-primary .h2, .card-hover-primary h3, .card-hover-primary .h3,
.card-hover-primary h4, .card-hover-primary .h4, .card-hover-primary h5, .card-hover-primary .h5, .card-hover-primary h6, .card-hover-primary .h6 {
  transition: color 0.2s ease-in-out;
}
.card-hover-primary .border-top, .card-hover-primary .border-end,
.card-hover-primary .border-bottom, .card-hover-primary .border-start {
  transition: border-color 0.2s ease-in-out;
}
.card-hover-primary .bg-secondary {
  transition: background-color 0.2s ease-in-out;
}
.card-hover-primary:hover {
  border-color: var(--si-primary) !important;
  background-color: var(--si-primary) !important;
}
.card-hover-primary:hover .card-title {
  color: #fff;
}
.card-hover-primary:hover .card-body {
  color: rgba(255, 255, 255, 0.7);
}
.card-hover-primary:hover .text-muted {
  color: rgba(255, 255, 255, 0.5) !important;
}
.card-hover-primary:hover .text-primary {
  color: #fff !important;
}
.card-hover-primary:hover .border-top, .card-hover-primary:hover .border-end,
.card-hover-primary:hover .border-bottom, .card-hover-primary:hover .border-start {
  border-color: rgba(255, 255, 255, 0.14) !important;
}
.card-hover-primary:hover .bg-secondary {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.card-hover-primary:hover h1, .card-hover-primary:hover .h1, .card-hover-primary:hover h2, .card-hover-primary:hover .h2, .card-hover-primary:hover h3, .card-hover-primary:hover .h3,
.card-hover-primary:hover h4, .card-hover-primary:hover .h4, .card-hover-primary:hover h5, .card-hover-primary:hover .h5, .card-hover-primary:hover h6, .card-hover-primary:hover .h6 {
  color: #fff !important;
}
.card-hover-primary:hover h1 > a, .card-hover-primary:hover .h1 > a, .card-hover-primary:hover h2 > a, .card-hover-primary:hover .h2 > a, .card-hover-primary:hover h3 > a, .card-hover-primary:hover .h3 > a,
.card-hover-primary:hover h4 > a, .card-hover-primary:hover .h4 > a, .card-hover-primary:hover h5 > a, .card-hover-primary:hover .h5 > a, .card-hover-primary:hover h6 > a, .card-hover-primary:hover .h6 > a {
  color: #fff !important;
}
.card-hover-primary:hover .text-dark:not(.badge.bg-white) {
  color: #fff !important;
}
.card-hover-primary:hover .text-nav:not(.badge.bg-white) {
  color: rgba(255, 255, 255, 0.85) !important;
}

.card-portfolio {
  border: 0;
  background-color: transparent;
}
.card-portfolio .card-img {
  border-radius: var(--si-card-border-radius);
  transform: translateZ(0);
  overflow: hidden;
}
.card-portfolio .card-img > img {
  display: block;
  transition: transform 0.35s ease-in-out;
}
.card-portfolio .card-body {
  padding-right: 0;
  padding-left: 0;
}
@media (min-width: 768px) {
  .card-portfolio .card-portfolio-meta {
    transition: opacity 0.35s ease-in-out;
    opacity: 0;
  }
  .card-portfolio:hover .card-img > img {
    transform: scale(1.05);
  }
  .card-portfolio:hover .card-portfolio-meta {
    opacity: 1;
  }
}

.accordion {
  --si-accordion-color: var(--si-body-color);
  --si-accordion-btn-font-weight: 600;
  --si-accordion-btn-color: #000000;
  --si-accordion-btn-icon-box-size: 2.25rem;
  --si-accordion-btn-icon-box-border-radius: 50%;
  --si-accordion-btn-icon-box-bg: #f3f6ff;
  --si-accordion-btn-icon-box-active-bg: #4c82f7;
  --si-accordion-btn-icon-box-active-shadow: 0 -0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9);
}

.accordion-button {
  transition: none;
  font-weight: var(--si-accordion-btn-font-weight);
}
.accordion-button:not(.collapsed) {
  box-shadow: inset 0 calc(var(--si-accordion-border-width) * -1) 0 var(--si-accordion-border-color);
}
.accordion-button::after {
  width: var(--si-accordion-btn-icon-box-size);
  height: var(--si-accordion-btn-icon-box-size);
  background-position: center;
  background-color: var(--si-accordion-btn-icon-box-bg);
  transition: all 0.35s;
  border-radius: var(--si-accordion-btn-icon-box-border-radius);
}
.accordion-button:not(.collapsed)::after {
  background-color: var(--si-accordion-btn-icon-box-active-bg);
  box-shadow: var(--si-accordion-btn-icon-box-active-shadow);
}

.breadcrumb {
  --si-breadcrumb-font-weight: 600;
  --si-breadcrumb-color: var(--si-gray-600);
  --si-breadcrumb-hover-color: var(--si-gray-800);
  font-weight: var(--si-breadcrumb-font-weight);
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}
.breadcrumb-item + .breadcrumb-item::before {
  margin-top: 0.15em;
  font-family: "boxicons";
  font-size: 1.15em;
  font-weight: normal;
  line-height: 1;
}
.breadcrumb-item > a {
  display: flex;
  align-items: center;
  color: var(--si-breadcrumb-color);
  text-decoration: none;
}
.breadcrumb-item:hover > a {
  color: var(--si-breadcrumb-hover-color);
}

.pagination {
  --si-pagination-font-weight: 600;
  --si-pagination-active-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9);
  margin-bottom: 0;
}

.page-link {
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: var(--si-pagination-border-radius);
  font-weight: var(--si-pagination-font-weight);
}
.page-link > i {
  margin-top: 0.0625rem;
  font-size: 1.425em;
}

.page-item {
  margin: 0 0.125rem;
}
.page-item:first-child {
  margin-left: 0;
}
.page-item:last-child {
  margin-right: 0;
}
.page-item.active .page-link {
  box-shadow: var(--si-pagination-active-box-shadow);
}

.pagination-sm {
  --si-pagination-border-radius: 0.25rem;
  --si-pagination-font-size: 0.75rem;
}

.pagination-lg {
  --si-pagination-border-radius: 0.5rem;
  --si-pagination-font-size: 1rem;
}

.badge.bg-secondary,
.badge.bg-light {
  --si-badge-color: #33354d;
}

a.badge {
  text-decoration: none;
}

.btn .badge {
  top: 1px;
}

.alert {
  --si-alert-link-color: inherit;
}
.alert .alert-link {
  color: var(--si-alert-link-color);
}

.alert-secondary {
  --si-alert-bg: #f3f5fd;
}

.alert-secondary,
.alert-light {
  --si-alert-color: #33354d;
  --si-alert-border-color: var(--si-border-color);
  --si-alert-link-color: #000000;
}

.alert-dark {
  --si-alert-border-color: #000000;
  --si-alert-bg: #000000;
  --si-alert-color: rgba(255, 255, 255, .85);
  --si-alert-link-color: #fff;
}

.alert-dismissible .btn-close {
  padding: calc(var(--si-alert-padding-y) * 1.375) var(--si-alert-padding-x);
}

.alert-primary .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230a4ddd'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75em auto no-repeat;
}

.alert-success .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23147739'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75em auto no-repeat;
}

.alert-info .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230a4ddd'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75em auto no-repeat;
}

.alert-warning .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ab7b00'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75em auto no-repeat;
}

.alert-danger .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c61111'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75em auto no-repeat;
}

.list-group {
  --si-list-group-color: #565973;
  --si-list-group-bg: transparent;
  --si-list-group-border-color: #e2e5f1;
  --si-list-group-border-width: 1px;
  --si-list-group-border-radius: 0.5rem;
  --si-list-group-item-padding-x: 1rem;
  --si-list-group-item-padding-y: 0.75rem;
  --si-list-group-action-color: #33354d;
  --si-list-group-action-hover-color: #4c82f7;
  --si-list-group-action-hover-bg: rgba(76, 130, 247, 0.12);
  --si-list-group-action-active-color: #fff;
  --si-list-group-action-active-bg: #4c82f7;
  --si-list-group-disabled-color: #9397ad;
  --si-list-group-disabled-bg: transparent;
  --si-list-group-active-color: #fff;
  --si-list-group-active-bg: #4c82f7;
  --si-list-group-active-border-color: #4c82f7;
  --si-list-group-active-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--si-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--si-list-group-item-padding-y) var(--si-list-group-item-padding-x);
  color: var(--si-list-group-color);
  text-decoration: none;
  background-color: var(--si-list-group-bg);
  border: var(--si-list-group-border-width) solid var(--si-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--si-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--si-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--si-list-group-active-color);
  background-color: var(--si-list-group-active-bg);
  border-color: var(--si-list-group-active-border-color);
  box-shadow: var(--si-list-group-active-box-shadow);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(var(--si-list-group-border-width) * -1);
  border-top-width: var(--si-list-group-border-width);
}

.list-group-item-action {
  width: 100%;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  color: var(--si-list-group-action-color);
  font-weight: 600;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--si-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--si-list-group-action-hover-bg);
}
.list-group-item-action.active {
  color: var(--si-list-group-action-active-color);
  background-color: var(--si-list-group-action-active-bg);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: var(--si-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: var(--si-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--si-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(var(--si-list-group-border-width) * -1);
  border-left-width: var(--si-list-group-border-width);
}

@media (min-width: 500px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: var(--si-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: var(--si-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--si-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--si-list-group-border-width) * -1);
    border-left-width: var(--si-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: var(--si-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: var(--si-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--si-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--si-list-group-border-width) * -1);
    border-left-width: var(--si-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--si-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: var(--si-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--si-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--si-list-group-border-width) * -1);
    border-left-width: var(--si-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--si-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: var(--si-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--si-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--si-list-group-border-width) * -1);
    border-left-width: var(--si-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: var(--si-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: var(--si-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--si-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--si-list-group-border-width) * -1);
    border-left-width: var(--si-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--si-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-flush .list-group-item-action {
  margin-bottom: 0.125rem;
  border: 0;
  border-radius: var(--si-list-group-border-radius);
}

.card .list-group-flush .list-group-item-action {
  margin-bottom: 0;
  border-bottom: var(--si-list-group-border-width) solid var(--si-list-group-border-color);
  border-radius: 0;
}
.card .list-group-flush .list-group-item-action:last-child {
  border-bottom: 0;
}
.card .list-group-flush .list-group-item-action.active {
  border-color: var(--si-list-group-active-border-color);
}

.offcanvas-body .list-group-flush .list-group-item-action {
  margin-bottom: 0.0625rem;
  border-radius: 0;
}

.toast-header {
  position: relative;
  border: 0;
  font-weight: 800;
}
.toast-header::after {
  position: absolute;
  left: var(--si-toast-padding-x);
  bottom: calc(var(--si-toast-border-width) * -1);
  width: calc(100% - var(--si-toast-padding-x) * 2);
  height: var(--si-toast-border-width);
  background-color: var(--si-toast-header-border-color);
  content: "";
}
.toast-header[class^=bg-]::after, .toast-header[class*=" bg-"]::after {
  display: none;
}
.toast-header .btn-close {
  margin-right: 0;
}

.modal-content {
  padding-right: var(--si-modal-padding);
  padding-left: var(--si-modal-padding);
}

.modal-header,
.modal-footer {
  padding-right: 0;
  padding-left: 0;
}

.modal-body {
  margin-right: calc(var(--si-modal-padding) * -1);
  margin-left: calc(var(--si-modal-padding) * -1);
}

.offcanvas-header {
  padding: calc(var(--si-offcanvas-padding-y) * 0.75) var(--si-offcanvas-padding-x);
}

.offcanvas-body {
  display: block !important;
}

.btn-prev, .btn-next {
  --si-carousel-nav-btn-border-radius: 50%;
  --si-carousel-nav-btn-bg: #fff;
  --si-carousel-nav-btn-color: #33354d;
  --si-carousel-nav-btn-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03);
  --si-carousel-nav-btn-hover-bg: #4c82f7;
  --si-carousel-nav-btn-hover-color: #fff;
  --si-carousel-nav-btn-hover-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9);
  --si-carousel-nav-btn-disabled-color: rgba(51, 53, 77, .5);
  --si-carousel-nav-btn-font-size: 1rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out;
  border: 0;
  border-radius: var(--si-carousel-nav-btn-border-radius);
  background-color: var(--si-carousel-nav-btn-bg);
  color: var(--si-carousel-nav-btn-color) !important;
  font-size: var(--si-carousel-nav-btn-font-size);
  box-shadow: var(--si-carousel-nav-btn-box-shadow);
}
.btn-prev:focus, .btn-next:focus {
  background-color: var(--si-carousel-nav-btn-bg);
  box-shadow: var(--si-carousel-nav-btn-box-shadow);
}
.btn-prev.bg-white, .btn-next.bg-white {
  color: #33354d !important;
}
.btn-prev:not(.bg-transparent):hover, .btn-next:not(.bg-transparent):hover {
  background-color: var(--si-carousel-nav-btn-hover-bg) !important;
  color: var(--si-carousel-nav-btn-hover-color) !important;
  box-shadow: var(--si-carousel-nav-btn-hover-box-shadow) !important;
}
.btn-prev > i, .btn-next > i {
  font-size: 1.5em;
}
.btn-prev.btn-sm > i, .btn-group-sm > .btn-prev.btn > i, .btn-next.btn-sm > i, .btn-group-sm > .btn-next.btn > i {
  font-size: 1.25em;
}
.btn-prev.btn-lg > i, .btn-group-lg > .btn-prev.btn > i, .btn-next.btn-lg > i, .btn-group-lg > .btn-next.btn > i {
  font-size: 1.75em;
}
.btn-prev.btn-xl > i, .btn-next.btn-xl > i {
  font-size: 2em;
}

.swiper-button-disabled,
.swiper-button-disabled:disabled {
  background-color: var(--si-carousel-nav-btn-bg);
  color: var(--si-carousel-nav-btn-disabled-color) !important;
  box-shadow: var(--si-carousel-nav-btn-box-shadow) !important;
  opacity: 1;
}

.swiper-button-disabled.shadow-none,
.swiper-button-disabled:disabled.shadow-none {
  box-shadow: none !important;
}

.pagination .swiper-button-disabled,
.pagination .swiper-button-disabled:disabled {
  background-color: transparent !important;
  box-shadow: none !important;
  pointer-events: none;
}

.swiper .btn-prev:not(.position-static),
.swiper .btn-next:not(.position-static) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}
.swiper .btn-prev {
  left: 1.25rem;
}
.swiper .btn-next {
  right: 1.25rem;
}

.swiper-nav-onhover .btn-prev,
.swiper-nav-onhover .btn-next {
  opacity: 0 !important;
}
.swiper-nav-onhover:hover .btn-prev, .swiper-nav-onhover:hover .btn-next {
  opacity: 1 !important;
}

.swiper-pagination {
  --si-carousel-pagination-bullet-width: 0.3125rem;
  --si-carousel-pagination-bullet-height: 0.3125rem;
  --si-carousel-pagination-bullet-spacer: 0.875rem;
  --si-carousel-pagination-bullet-border-radius: 50rem;
  --si-carousel-pagination-bullet-bg: #b4b7c9;
  --si-carousel-pagination-bullet-active-width: 1.5rem;
  --si-carousel-pagination-bullet-active-bg: #4c82f7;
  --si-carousel-pagination-progressbar-height: 0.1875rem;
  --si-carousel-pagination-progressbar-bg: #e2e5f1;
  --si-carousel-pagination-progressbar-fill-bg: #4c82f7;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1.25rem;
}

.swiper-pagination-bullet {
  width: var(--si-carousel-pagination-bullet-width);
  height: var(--si-carousel-pagination-bullet-height);
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out, background-color 0.2s ease-in-out;
  border-radius: var(--si-carousel-pagination-bullet-border-radius);
  background-color: var(--si-carousel-pagination-bullet-bg);
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: var(--si-carousel-pagination-bullet-active-bg);
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 1.25rem;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet {
  margin-right: calc(var(--si-carousel-pagination-bullet-spacer) * 0.5);
  margin-left: calc(var(--si-carousel-pagination-bullet-spacer) * 0.5);
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet-active {
  width: var(--si-carousel-pagination-bullet-active-width);
}

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-vertical {
  flex-direction: column;
  right: 1.25rem;
}
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-bullets.swiper-pagination-vertical .swiper-pagination-bullet {
  margin-top: calc(var(--si-carousel-pagination-bullet-spacer) * 0.5);
  margin-bottom: calc(var(--si-carousel-pagination-bullet-spacer) * 0.5);
}
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet-active,
.swiper-pagination-bullets.swiper-pagination-vertical .swiper-pagination-bullet-active {
  height: var(--si-carousel-pagination-bullet-active-width);
}

.swiper-pagination-progressbar {
  background-color: var(--si-carousel-pagination-progressbar-bg);
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: var(--si-carousel-pagination-progressbar-fill-bg);
}

.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
  height: var(--si-carousel-pagination-progressbar-height);
}

.swiper-vertical > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-vertical {
  width: var(--si-carousel-pagination-progressbar-height);
}

.page-link.btn-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
}

.swiper-scrollbar {
  --si-carousel-scrollbar-width: 0.1875rem;
  --si-carousel-scrollbar-bg: #e2e5f1;
  --si-carousel-scrollbar-drag-bg: #a2a6bc;
  background-color: var(--si-carousel-scrollbar-bg);
}

.swiper-scrollbar-drag {
  background-color: var(--si-carousel-scrollbar-drag-bg);
}

.swiper-vertical > .swiper-scrollbar {
  top: 0;
  height: 100%;
  width: var(--si-carousel-scrollbar-width);
}

.swiper-horizontal > .swiper-scrollbar {
  left: 0;
  width: 100%;
  height: var(--si-carousel-scrollbar-width);
}

.scrollbar-hover .swiper-scrollbar {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}
.scrollbar-hover:hover .swiper-scrollbar {
  opacity: 1;
}

.swiper-tabs {
  position: relative;
  height: 100%;
}

.swiper-tab {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.swiper-tab.active {
  position: relative;
  opacity: 1;
  visibility: visible;
}

.from-top, .from-bottom,
.from-start, .from-end,
.scale-up, .scale-down,
.fade-in {
  transition: all 0.4s 0.4s ease-in-out;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.from-top {
  transform: translateY(-2.25rem);
}

.from-bottom {
  transform: translateY(2.25rem);
}

.from-start {
  transform: translateX(-2.25rem);
}

.from-end {
  transform: translateX(2.25rem);
}

.scale-up {
  transform: scale(0.85);
}

.scale-down {
  transform: scale(1.15);
}

.delay-1 {
  transition-delay: 0.5s;
}

.delay-2 {
  transition-delay: 0.7s;
}

.delay-3 {
  transition-delay: 0.9s;
}

.delay-4 {
  transition-delay: 1.1s;
}

.swiper-slide-active .from-top, .swiper-slide-active .from-bottom {
  transform: translateY(0);
  opacity: 1;
}
.swiper-slide-active .from-start, .swiper-slide-active .from-end {
  transform: translateX(0);
  opacity: 1;
}
.swiper-slide-active .scale-up, .swiper-slide-active .scale-down {
  transform: scale(1);
  opacity: 1;
}
.swiper-slide-active .fade-in {
  opacity: 1;
}

.mobile-app-slider {
  max-width: 328px;
  border-radius: 4rem;
}
@media (min-width: 768px) {
  .mobile-app-slider {
    max-width: 100%;
  }
  .mobile-app-slider .swiper-slide {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.8;
  }
  .mobile-app-slider .swiper-slide.swiper-slide-active {
    opacity: 1;
  }
}

.gallery {
  --si-gallery-overlay-bg: rgba(11, 15, 25, 0.4);
  --si-gallery-indicator-size: 4.5rem;
  --si-gallery-image-indicator-color: #fff;
  --si-gallery-image-indicator-font-size: 2rem;
  --si-gallery-video-indicator-bg: #fff;
  --si-gallery-video-indicator-color: #33354d;
  --si-gallery-video-indicator-font-size: 2rem;
  --si-gallery-video-indicator-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03);
  --si-gallery-video-indicator-hover-bg: #4c82f7;
  --si-gallery-video-indicator-hover-color: #fff;
  --si-gallery-video-indicator-hover-box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9);
}

.gallery-item {
  display: block;
  position: relative;
  text-decoration: none !important;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.gallery-item > img {
  display: block;
  width: 100%;
}
.gallery-item::before {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
  background-color: var(--si-gallery-overlay-bg);
  content: "";
  opacity: 0;
  z-index: 1;
}
.gallery-item::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--si-gallery-indicator-size);
  height: var(--si-gallery-indicator-size);
  margin-top: calc(var(--si-gallery-indicator-size) * -0.5);
  margin-left: calc(var(--si-gallery-indicator-size) * -0.5);
  transform: scale(0.3);
  color: var(--si-gallery-image-indicator-color);
  font-family: "boxicons";
  font-size: var(--si-gallery-image-indicator-font-size);
  text-align: center;
  line-height: var(--si-gallery-indicator-size);
  content: "\ec90";
  z-index: 5;
}
.gallery-item .gallery-item-caption {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 1rem;
  transform: translateY(0.5rem);
  color: #fff;
  z-index: 5;
}
.gallery-item::after,
.gallery-item .gallery-item-caption {
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.gallery-item:hover::before, .gallery-item:hover::after,
.gallery-item:hover .gallery-item-caption, .gallery-item.is-hovered::before, .gallery-item.is-hovered::after,
.gallery-item.is-hovered .gallery-item-caption {
  transform: none;
  opacity: 1;
}
.gallery-item.video-item::after {
  padding-top: 0.0625rem;
  padding-left: 0.25rem;
  transform: none;
  border-radius: 50%;
  background-color: var(--si-gallery-video-indicator-bg);
  color: var(--si-gallery-video-indicator-color);
  font-size: var(--si-gallery-video-indicator-font-size);
  opacity: 1;
  box-shadow: var(--si-gallery-video-indicator-box-shadow);
  content: "\ebbd";
}
.gallery-item.video-item:hover::after {
  background-color: var(--si-gallery-video-indicator-hover-bg);
  color: var(--si-gallery-video-indicator-hover-color);
  box-shadow: var(--si-gallery-video-indicator-hover-box-shadow);
}

.lg-backdrop {
  z-index: 1055;
}

.lg-outer {
  z-index: 1060;
}
.lg-outer .lg-thumb-item:hover,
.lg-outer .lg-thumb-item.active {
  border-color: var(--si-primary);
}

.lg-on {
  overflow: hidden;
}

.lg-item:focus, .lg:focus, .lg-outer:focus,
.lg-inner:focus {
  outline: none;
}

.steps {
  --si-steps-padding-y: 1.5rem;
  --si-steps-padding-x: 1.5rem;
  --si-steps-number-size: 7.625rem;
  --si-steps-number-inner-size: 5.625rem;
  --si-steps-number-size-sm: 5rem;
  --si-steps-number-inner-size-sm: 3.5rem;
  --si-steps-number-border-radius: 50%;
  --si-steps-number-bg: #f3f6ff;
  --si-steps-number-inner-bg: #fff;
  --si-steps-number-inner-box-shadow: 0 0.275rem 0.75rem -0.0625rem rgba(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgba(11, 15, 25, 0.03);
  --si-steps-number-font-size: 2rem;
  --si-steps-number-font-size-sm: 1.5rem;
  --si-steps-number-color: var(--si-heading-color);
  --si-steps-connect-width: var(--si-border-width);
  --si-steps-connect-color: var(--si-border-color);
  display: flex;
  flex-direction: column;
}

.step {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--si-steps-padding-y) 0;
}
.step:first-child {
  padding-top: 0 !important;
}
.step:last-child {
  padding-bottom: 0 !important;
}
.step::before, .step::after {
  position: absolute;
  left: calc(var(--si-steps-number-size) * 0.5);
  width: var(--si-steps-connect-width);
  height: 50%;
  content: "";
}
.step::before {
  top: 0;
}
.step::after {
  top: 50%;
}
.step:not(:first-child)::before {
  background-color: var(--si-steps-connect-color);
}
.step:not(:last-child)::after {
  background-color: var(--si-steps-connect-color);
}

.step-number {
  position: relative;
  width: var(--si-steps-number-size);
  height: var(--si-steps-number-size);
  flex-shrink: 0;
  padding-top: calc((var(--si-steps-number-size) - var(--si-steps-number-inner-size)) * 0.5);
  padding-left: calc((var(--si-steps-number-size) - var(--si-steps-number-inner-size)) * 0.5);
  border-radius: var(--si-steps-number-border-radius);
  background-color: var(--si-steps-number-bg);
  color: var(--si-steps-number-color);
  font-size: var(--si-steps-number-font-size);
  font-weight: 800;
  z-index: 2;
}
.step-number .step-number-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--si-steps-number-inner-size);
  height: var(--si-steps-number-inner-size);
  border-radius: var(--si-steps-number-border-radius);
  background-color: var(--si-steps-number-inner-bg);
  box-shadow: var(--si-steps-number-inner-box-shadow);
}

.step-body {
  padding-left: var(--si-steps-padding-x);
}

.steps-sm .step::before,
.steps-sm .step::after {
  left: calc(var(--si-steps-number-size-sm) * 0.5);
}
.steps-sm .step-number {
  width: var(--si-steps-number-size-sm);
  height: var(--si-steps-number-size-sm);
  padding-top: 0.75rem;
  padding-left: 0.75rem;
  font-size: var(--si-steps-number-font-size-sm);
}
.steps-sm .step-number .step-number-inner {
  width: var(--si-steps-number-inner-size-sm);
  height: var(--si-steps-number-inner-size-sm);
}

@media (min-width: 500px) {
  .steps-horizontal-sm.steps {
    flex-direction: row;
    margin-right: calc(var(--si-steps-padding-x) * -1);
    margin-left: calc(var(--si-steps-padding-x) * -1);
  }
  .steps-horizontal-sm .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--si-steps-padding-x);
  }
  .steps-horizontal-sm .step::before, .steps-horizontal-sm .step::after {
    top: calc(var(--si-steps-number-size) * 0.5);
    height: var(--si-steps-connect-width);
  }
  .steps-horizontal-sm .step::before {
    display: none;
    left: 0;
  }
  .steps-horizontal-sm .step::after {
    left: var(--si-steps-padding-x);
    width: 100%;
  }
  .steps-horizontal-sm.steps-sm .step::before, .steps-horizontal-sm.steps-sm .step::after {
    top: calc(var(--si-steps-number-size-sm) * 0.5);
  }
  .steps-horizontal-sm .step-number {
    margin-bottom: var(--si-steps-padding-y);
  }
  .steps-horizontal-sm .step-body {
    padding-left: 0;
  }
  .steps-horizontal-sm.steps-center {
    text-align: center;
  }
  .steps-horizontal-sm.steps-center .step::after {
    left: 50%;
    width: 50%;
  }
  .steps-horizontal-sm.steps-center .step:not(:first-child)::before {
    display: block;
    width: 50%;
  }
  .steps-horizontal-sm.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-sm.steps-end {
    text-align: right;
  }
  .steps-horizontal-sm.steps-end .step:not(:first-child)::before {
    display: block;
    width: calc(100% - var(--si-steps-padding-x));
  }
  .steps-horizontal-sm.steps-end .step::after {
    right: 0;
    left: auto;
    width: var(--si-steps-padding-x);
  }
  .steps-horizontal-sm.steps-end .step:last-child::after {
    display: none;
  }
  .steps-horizontal-sm.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .steps-horizontal-md.steps {
    flex-direction: row;
    margin-right: calc(var(--si-steps-padding-x) * -1);
    margin-left: calc(var(--si-steps-padding-x) * -1);
  }
  .steps-horizontal-md .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--si-steps-padding-x);
  }
  .steps-horizontal-md .step::before, .steps-horizontal-md .step::after {
    top: calc(var(--si-steps-number-size) * 0.5);
    height: var(--si-steps-connect-width);
  }
  .steps-horizontal-md .step::before {
    display: none;
    left: 0;
  }
  .steps-horizontal-md .step::after {
    left: var(--si-steps-padding-x);
    width: 100%;
  }
  .steps-horizontal-md.steps-sm .step::before, .steps-horizontal-md.steps-sm .step::after {
    top: calc(var(--si-steps-number-size-sm) * 0.5);
  }
  .steps-horizontal-md .step-number {
    margin-bottom: var(--si-steps-padding-y);
  }
  .steps-horizontal-md .step-body {
    padding-left: 0;
  }
  .steps-horizontal-md.steps-center {
    text-align: center;
  }
  .steps-horizontal-md.steps-center .step::after {
    left: 50%;
    width: 50%;
  }
  .steps-horizontal-md.steps-center .step:not(:first-child)::before {
    display: block;
    width: 50%;
  }
  .steps-horizontal-md.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-md.steps-end {
    text-align: right;
  }
  .steps-horizontal-md.steps-end .step:not(:first-child)::before {
    display: block;
    width: calc(100% - var(--si-steps-padding-x));
  }
  .steps-horizontal-md.steps-end .step::after {
    right: 0;
    left: auto;
    width: var(--si-steps-padding-x);
  }
  .steps-horizontal-md.steps-end .step:last-child::after {
    display: none;
  }
  .steps-horizontal-md.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .steps-horizontal-lg.steps {
    flex-direction: row;
    margin-right: calc(var(--si-steps-padding-x) * -1);
    margin-left: calc(var(--si-steps-padding-x) * -1);
  }
  .steps-horizontal-lg .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--si-steps-padding-x);
  }
  .steps-horizontal-lg .step::before, .steps-horizontal-lg .step::after {
    top: calc(var(--si-steps-number-size) * 0.5);
    height: var(--si-steps-connect-width);
  }
  .steps-horizontal-lg .step::before {
    display: none;
    left: 0;
  }
  .steps-horizontal-lg .step::after {
    left: var(--si-steps-padding-x);
    width: 100%;
  }
  .steps-horizontal-lg.steps-sm .step::before, .steps-horizontal-lg.steps-sm .step::after {
    top: calc(var(--si-steps-number-size-sm) * 0.5);
  }
  .steps-horizontal-lg .step-number {
    margin-bottom: var(--si-steps-padding-y);
  }
  .steps-horizontal-lg .step-body {
    padding-left: 0;
  }
  .steps-horizontal-lg.steps-center {
    text-align: center;
  }
  .steps-horizontal-lg.steps-center .step::after {
    left: 50%;
    width: 50%;
  }
  .steps-horizontal-lg.steps-center .step:not(:first-child)::before {
    display: block;
    width: 50%;
  }
  .steps-horizontal-lg.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-lg.steps-end {
    text-align: right;
  }
  .steps-horizontal-lg.steps-end .step:not(:first-child)::before {
    display: block;
    width: calc(100% - var(--si-steps-padding-x));
  }
  .steps-horizontal-lg.steps-end .step::after {
    right: 0;
    left: auto;
    width: var(--si-steps-padding-x);
  }
  .steps-horizontal-lg.steps-end .step:last-child::after {
    display: none;
  }
  .steps-horizontal-lg.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .steps-horizontal-xl.steps {
    flex-direction: row;
    margin-right: calc(var(--si-steps-padding-x) * -1);
    margin-left: calc(var(--si-steps-padding-x) * -1);
  }
  .steps-horizontal-xl .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--si-steps-padding-x);
  }
  .steps-horizontal-xl .step::before, .steps-horizontal-xl .step::after {
    top: calc(var(--si-steps-number-size) * 0.5);
    height: var(--si-steps-connect-width);
  }
  .steps-horizontal-xl .step::before {
    display: none;
    left: 0;
  }
  .steps-horizontal-xl .step::after {
    left: var(--si-steps-padding-x);
    width: 100%;
  }
  .steps-horizontal-xl.steps-sm .step::before, .steps-horizontal-xl.steps-sm .step::after {
    top: calc(var(--si-steps-number-size-sm) * 0.5);
  }
  .steps-horizontal-xl .step-number {
    margin-bottom: var(--si-steps-padding-y);
  }
  .steps-horizontal-xl .step-body {
    padding-left: 0;
  }
  .steps-horizontal-xl.steps-center {
    text-align: center;
  }
  .steps-horizontal-xl.steps-center .step::after {
    left: 50%;
    width: 50%;
  }
  .steps-horizontal-xl.steps-center .step:not(:first-child)::before {
    display: block;
    width: 50%;
  }
  .steps-horizontal-xl.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-xl.steps-end {
    text-align: right;
  }
  .steps-horizontal-xl.steps-end .step:not(:first-child)::before {
    display: block;
    width: calc(100% - var(--si-steps-padding-x));
  }
  .steps-horizontal-xl.steps-end .step::after {
    right: 0;
    left: auto;
    width: var(--si-steps-padding-x);
  }
  .steps-horizontal-xl.steps-end .step:last-child::after {
    display: none;
  }
  .steps-horizontal-xl.steps-end .step-number {
    margin-left: auto;
  }
}
@media (min-width: 1400px) {
  .steps-horizontal-xxl.steps {
    flex-direction: row;
    margin-right: calc(var(--si-steps-padding-x) * -1);
    margin-left: calc(var(--si-steps-padding-x) * -1);
  }
  .steps-horizontal-xxl .step {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 var(--si-steps-padding-x);
  }
  .steps-horizontal-xxl .step::before, .steps-horizontal-xxl .step::after {
    top: calc(var(--si-steps-number-size) * 0.5);
    height: var(--si-steps-connect-width);
  }
  .steps-horizontal-xxl .step::before {
    display: none;
    left: 0;
  }
  .steps-horizontal-xxl .step::after {
    left: var(--si-steps-padding-x);
    width: 100%;
  }
  .steps-horizontal-xxl.steps-sm .step::before, .steps-horizontal-xxl.steps-sm .step::after {
    top: calc(var(--si-steps-number-size-sm) * 0.5);
  }
  .steps-horizontal-xxl .step-number {
    margin-bottom: var(--si-steps-padding-y);
  }
  .steps-horizontal-xxl .step-body {
    padding-left: 0;
  }
  .steps-horizontal-xxl.steps-center {
    text-align: center;
  }
  .steps-horizontal-xxl.steps-center .step::after {
    left: 50%;
    width: 50%;
  }
  .steps-horizontal-xxl.steps-center .step:not(:first-child)::before {
    display: block;
    width: 50%;
  }
  .steps-horizontal-xxl.steps-center .step-number {
    margin-right: auto;
    margin-left: auto;
  }
  .steps-horizontal-xxl.steps-end {
    text-align: right;
  }
  .steps-horizontal-xxl.steps-end .step:not(:first-child)::before {
    display: block;
    width: calc(100% - var(--si-steps-padding-x));
  }
  .steps-horizontal-xxl.steps-end .step::after {
    right: 0;
    left: auto;
    width: var(--si-steps-padding-x);
  }
  .steps-horizontal-xxl.steps-end .step:last-child::after {
    display: none;
  }
  .steps-horizontal-xxl.steps-end .step-number {
    margin-left: auto;
  }
}
.steps-horizontal.steps {
  flex-direction: row;
  margin-right: calc(var(--si-steps-padding-x) * -1);
  margin-left: calc(var(--si-steps-padding-x) * -1);
}
.steps-horizontal .step {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 var(--si-steps-padding-x);
}
.steps-horizontal .step::before, .steps-horizontal .step::after {
  top: calc(var(--si-steps-number-size) * 0.5);
  height: var(--si-steps-connect-width);
}
.steps-horizontal .step::before {
  display: none;
  left: 0;
}
.steps-horizontal .step::after {
  left: var(--si-steps-padding-x);
  width: 100%;
}
.steps-horizontal.steps-sm .step::before, .steps-horizontal.steps-sm .step::after {
  top: calc(var(--si-steps-number-size-sm) * 0.5);
}
.steps-horizontal .step-number {
  margin-bottom: var(--si-steps-padding-y);
}
.steps-horizontal .step-body {
  padding-left: 0;
}
.steps-horizontal.steps-center {
  text-align: center;
}
.steps-horizontal.steps-center .step::after {
  left: 50%;
  width: 50%;
}
.steps-horizontal.steps-center .step:not(:first-child)::before {
  display: block;
  width: 50%;
}
.steps-horizontal.steps-center .step-number {
  margin-right: auto;
  margin-left: auto;
}
.steps-horizontal.steps-end {
  text-align: right;
}
.steps-horizontal.steps-end .step:not(:first-child)::before {
  display: block;
  width: calc(100% - var(--si-steps-padding-x));
}
.steps-horizontal.steps-end .step::after {
  right: 0;
  left: auto;
  width: var(--si-steps-padding-x);
}
.steps-horizontal.steps-end .step:last-child::after {
  display: none;
}
.steps-horizontal.steps-end .step-number {
  margin-left: auto;
}

@media (max-width: 499.98px) {
  .steps-sm .step,
.step {
    padding: calc(var(--si-steps-padding-y) * 0.625) 0;
  }
  .steps-sm .step::before, .steps-sm .step::after,
.step::before,
.step::after {
    left: calc(var(--si-steps-number-size) * 0.625 * 0.5);
  }
  .steps-sm .step-number,
.step-number {
    width: calc(var(--si-steps-number-size) * 0.625);
    height: calc(var(--si-steps-number-size) * 0.625);
    padding-top: calc((var(--si-steps-number-size) * 0.625 - var(--si-steps-number-inner-size) * 0.625) * 0.5);
    padding-left: calc((var(--si-steps-number-size) * 0.625 - var(--si-steps-number-inner-size) * 0.625) * 0.5);
    font-size: calc(var(--si-steps-number-font-size) * 0.625);
  }
  .steps-sm .step-number .step-number-inner,
.step-number .step-number-inner {
    width: calc(var(--si-steps-number-inner-size) * 0.625);
    height: calc(var(--si-steps-number-inner-size) * 0.625);
  }
  .step-body {
    padding-left: calc(var(--si-steps-padding-x) * 0.875);
  }
}
.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax .jarallax-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-position: center !important;
  background-size: cover !important;
}

[data-disable-parallax-down=xs] {
  transform: none !important;
}

@media (max-width: 499.98px) {
  [data-disable-parallax-down=sm] {
    transform: none !important;
  }
}

@media (max-width: 767.98px) {
  [data-disable-parallax-down=md] {
    transform: none !important;
  }
}

@media (max-width: 991.98px) {
  [data-disable-parallax-down=lg] {
    transform: none !important;
  }
}

@media (max-width: 1199.98px) {
  [data-disable-parallax-down=xl] {
    transform: none !important;
  }
}

@media (min-width: 500px) {
  [data-disable-parallax-up=sm] {
    transform: none !important;
  }
}

@media (min-width: 768px) {
  [data-disable-parallax-up=md] {
    transform: none !important;
  }
}

@media (min-width: 992px) {
  [data-disable-parallax-up=lg] {
    transform: none !important;
  }
}

@media (min-width: 1200px) {
  [data-disable-parallax-up=xl] {
    transform: none !important;
  }
}

.parallax {
  position: relative;
}

.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.parallax-layer:first-child {
  position: relative;
}
.parallax-layer > img {
  display: block;
  width: 100%;
}

.tilt-3d {
  transform-style: preserve-3d;
}
.tilt-3d .tilt-3d-inner {
  transform: translateZ(2rem);
}

img-comparison-slider {
  display: block;
  --divider-width: 0.1875rem;
  --divider-color: #4c82f7;
  --default-handle-opacity: 1;
}
img-comparison-slider:focus {
  outline: none;
}

.audio-player {
  --prefixaudio-player-theme: #4c82f7;
  --seek-before-width: 0%;
  --volume-before-width: 100%;
  --buffered-width: 0%;
  display: flex;
  align-items: center;
  width: 100%;
}

.ap-play-button {
  padding-top: 0.125rem;
  font-family: "boxicons";
  font-size: 1.5rem;
  font-weight: normal;
  font-style: normal;
}
.ap-play-button::before {
  content: "\ebbd";
}
.ap-play-button.ap-pause::before {
  content: "\ebae";
}

.ap-volume-button + .dropdown-menu {
  left: 50% !important;
  width: 2.125rem;
  min-width: 2.125rem;
  max-width: 2.125rem;
  height: 6.5rem;
  margin-left: -1.0625rem !important;
}

.ap-seek-slider,
.ap-volume-slider {
  position: relative;
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
}
.ap-seek-slider::before,
.ap-volume-slider::before {
  position: absolute;
  left: 0;
  cursor: pointer;
  content: "";
}

.ap-seek-slider {
  width: 100%;
  height: 1.125rem;
}

.ap-seek-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.25rem;
  background: linear-gradient(to right, #d4d7e5 var(--buffered-width), rgba(226, 229, 241, 0.9) var(--buffered-width));
  cursor: pointer;
}

.ap-seek-slider::before {
  top: 0.4375rem;
  width: var(--seek-before-width);
  height: 0.25rem;
  background-color: var(--prefixaudio-player-theme);
}

.ap-seek-slider::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  box-sizing: content-box;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: var(--prefixaudio-player-theme);
  cursor: pointer;
  margin: -0.375rem 0 0 0;
}

.ap-seek-slider:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

.ap-seek-slider::-moz-range-track {
  width: 100%;
  height: 0.25rem;
  background: linear-gradient(to right, #d4d7e5 var(--buffered-width), rgba(226, 229, 241, 0.9) var(--buffered-width));
  cursor: pointer;
}

.ap-seek-slider::-moz-range-progress {
  background-color: var(--prefixaudio-player-theme);
}

.ap-seek-slider::-moz-focus-outer {
  border: 0;
}

.ap-seek-slider::-moz-range-thumb {
  box-sizing: content-box;
  border: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: var(--prefixaudio-player-theme);
  cursor: pointer;
}

.ap-seek-slider:active::-moz-range-thumb {
  transform: scale(1.2);
}

.ap-volume-slider {
  top: 1.925rem;
  right: 1.5rem;
  width: 5rem;
  height: 0.5625rem;
  transform: rotate(-90deg);
}

.ap-volume-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.1875rem;
  background-color: var(--prefixaudio-player-theme);
  cursor: pointer;
}

.ap-volume-slider::before {
  top: 0.1875rem;
  width: var(--volume-before-width);
  height: 0.1875rem;
  background-color: #fff;
}

.ap-volume-slider::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  box-sizing: content-box;
  height: 0.5625rem;
  width: 0.5625rem;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  margin: -0.1875rem 0 0 0;
}

.ap-volume-slider:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

.ap-volume-slider::-moz-range-track {
  width: 100%;
  height: 0.1875rem;
  background-color: var(--prefixaudio-player-theme);
  cursor: pointer;
}

.ap-volume-slider::-moz-range-progress {
  background-color: #fff;
}

.ap-volume-slider::-moz-focus-outer {
  border: 0;
}

.ap-volume-slider::-moz-range-thumb {
  box-sizing: content-box;
  border: 0;
  height: 0.5625rem;
  width: 0.5625rem;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.ap-volume-slider:active::-moz-range-thumb {
  transform: scale(1.2);
}

.dark-mode {
  --si-gray-600: rgba(255, 255, 255, .5);
  --si-gray-700: rgba(255, 255, 255, .7);
  --si-gray-800: rgba(255, 255, 255, .85);
  --si-gray-900: #fff;
  --si-body-bg: #000000;
  --si-body-color: rgba(255, 255, 255, 0.7);
  --si-secondary-bg: rgba(255, 255, 255, .04);
  --si-heading-color: #fff;
  --si-heading-link-color: rgba(255, 255, 255, 0.85);
  --si-heading-link-hover-color: #4c82f7;
  --si-nav-link-color: rgba(255, 255, 255, 0.85);
  --si-border-color: rgba(255, 255, 255, 0.14);
  --si-code-color: #f33185;
}
.dark-mode .d-dark-mode-none {
  display: none !important;
}
.dark-mode .d-dark-mode-block {
  display: block !important;
}
.dark-mode .d-dark-mode-inline {
  display: inline !important;
}
.dark-mode .d-dark-mode-inline-block {
  display: inline-block !important;
}
.dark-mode .d-dark-mode-inline-flex {
  display: inline-flex !important;
}
.dark-mode .d-dark-mode-flex {
  display: flex !important;
}
.dark-mode .text-border {
  color: var(--si-border-color) !important;
}
.dark-mode .text-dark:not(.badge) {
  color: #fff !important;
}
.dark-mode .text-nav:not(.badge.bg-white) {
  color: rgba(255, 255, 255, 0.85) !important;
}
.dark-mode .text-body {
  color: rgba(255, 255, 255, 0.7) !important;
}
.dark-mode .border-dark {
  border-color: var(--si-border-color) !important;
}
.dark-mode .bg-light:not(.badge),
.dark-mode .card.card-hover.bg-light {
  background-color: #000000 !important;
}
.dark-mode .bg-secondary,
.dark-mode .card.bg-light {
  background-color: var(--si-secondary-bg) !important;
}
.dark-mode .bg-secondary.badge {
  color: var(--si-body-color);
}
.dark-mode .shadow-dark-mode-none {
  box-shadow: none !important;
}
.dark-mode .bg-white {
  --si-body-color: #565973;
  --si-heading-color: #000000;
  --si-nav-link-color: #33354d;
  --si-border-color: #e2e5f1;
}
.dark-mode .bg-white p, .dark-mode .bg-white ul, .dark-mode .bg-white ol, .dark-mode .bg-white span, .dark-mode .bg-white dd {
  color: var(--si-body-color);
}
.dark-mode .bg-white .text-muted {
  color: #9397ad !important;
}
.dark-mode .bg-white .text-dark {
  color: #000000 !important;
}
.dark-mode .img-thumbnail {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-mode .dark-mode-img {
  display: block;
}
.dark-mode .light-mode-img {
  display: none;
}
.dark-mode .table {
  --si-table-th-color: #fff;
  --si-table-striped-bg: rgba(255, 255, 255, 0.0375);
  --si-table-hover-bg: rgba(255, 255, 255, 0.05);
  --si-table-hover-color: var(--si-body-color);
}
.dark-mode .table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}
.dark-mode .btn-outline-secondary {
  --si-btn-color: rgba(255, 255, 255, .85);
  --si-btn-hover-color: #33354d;
  --si-btn-active-color: #33354d;
  --si-btn-border-color: rgba(255, 255, 255, .25);
  --si-btn-hover-bg: #fff;
  --si-btn-active-bg: #fff;
}
.dark-mode .btn-secondary:not(.bg-white) {
  --si-btn-color: rgba(255, 255, 255, .85);
  --si-btn-hover-color: #fff;
  --si-btn-active-color: #fff;
  --si-btn-border-color: transparent;
  --si-btn-hover-border-color: transparent;
  --si-btn-active-border-color: transparent;
  --si-btn-bg: rgba(255, 255, 255, .06);
  --si-btn-hover-bg: rgba(255, 255, 255, .12);
  --si-btn-active-bg: rgba(255, 255, 255, .12);
}
.dark-mode .btn-secondary,
.dark-mode .btn-outline-secondary,
.dark-mode .btn-light,
.dark-mode .btn-outline-light {
  --si-btn-disabled-color: rgba(255, 255, 255, .5);
}
.dark-mode .btn-dark {
  --si-btn-color: #33354d !important;
  --si-btn-hover-color: #33354d !important;
  --si-btn-active-color: #33354d !important;
  --si-btn-bg: #eff2fc;
  --si-btn-hover-bg: #d5ddf7;
  --si-btn-active-bg: #d5ddf7;
}
.dark-mode .btn-video {
  --si-btn-video-bg: rgba(255, 255, 255, 0.05);
  --si-btn-video-hover-bg: #4c82f7;
  --si-btn-video-color: #fff;
  --si-btn-video-hover-color: #fff;
  --si-btn-video-shadow: none;
  --si-btn-video-hover-shadow: 0 0.5rem 1.125rem -0.5rem rgba(76, 130, 247, 0.9);
}
.dark-mode .btn-scroll-top {
  --si-btn-scroll-top-color: #fff;
  --si-btn-scroll-top-hover-color: #fff;
  --si-btn-scroll-top-bg: rgba(255, 255, 255, 0.15);
  --si-btn-scroll-top-hover-bg: rgba(255, 255, 255, 0.3);
}
.dark-mode .form-label,
.dark-mode .col-form-label {
  color: #fff;
}
.dark-mode .form-text {
  color: rgba(255, 255, 255, 0.5);
}
.dark-mode .form-control,
.dark-mode .form-select {
  border-color: rgba(255, 255, 255, 0.18);
  background-color: rgba(255, 255, 255, 0.02);
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .form-control:focus,
.dark-mode .form-select:focus {
  border-color: rgba(255, 255, 255, 0.35);
  box-shadow: none;
}
.dark-mode .form-control:disabled, .dark-mode .form-control[readonly],
.dark-mode .form-select:disabled,
.dark-mode .form-select[readonly] {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.01);
}
.dark-mode .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.dark-mode .form-control::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.dark-mode .form-control::-webkit-file-upload-button {
  background-color: rgba(255, 255, 255, 0.02);
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .form-control::file-selector-button {
  background-color: rgba(255, 255, 255, 0.02);
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: rgba(255, 255, 255, 0.02);
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: rgba(255, 255, 255, 0.02);
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .form-control::-webkit-file-upload-button {
  background-color: rgba(255, 255, 255, 0.02);
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: rgba(255, 255, 255, 0.02);
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .form-control:focus:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f2f2f2;
  color: #565973;
}
.dark-mode .form-control:focus:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
  color: #565973;
}
.dark-mode .form-control:focus:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f2f2f2;
  color: #565973;
}
.dark-mode .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.dark-mode .form-floating .form-control::-moz-placeholder, .dark-mode .form-floating .form-select::-moz-placeholder {
  color: transparent;
}
.dark-mode .form-floating .form-control::placeholder,
.dark-mode .form-floating .form-select::placeholder {
  color: transparent;
}
.dark-mode .form-floating .form-control:focus ~ label,
.dark-mode .form-floating .form-select:focus ~ label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .form-range::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.09);
}
.dark-mode .form-range::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.09);
}
.dark-mode .form-check-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .form-check-input:not(:checked):not(.is-invalid):not(:invalid) {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-mode .form-check-input:not(:checked) {
  background-color: transparent;
}
.dark-mode .form-switch .form-check-input {
  background-color: rgba(255, 255, 255, 0.25);
}
.dark-mode .form-switch .form-check-input:checked {
  background-color: #4c82f7;
}
.dark-mode .form-switch.mode-switch .form-check-label:first-of-type, .dark-mode .form-switch.price-switch .form-check-label:first-of-type {
  color: #fff;
}
.dark-mode .form-switch.mode-switch .form-check-input, .dark-mode .form-switch.price-switch .form-check-input {
  background-color: #4c82f7;
}
.dark-mode .form-switch.mode-switch .form-check-input:checked ~ .form-check-label:first-of-type, .dark-mode .form-switch.price-switch .form-check-input:checked ~ .form-check-label:first-of-type {
  color: rgba(255, 255, 255, 0.6);
}
.dark-mode .form-switch.mode-switch .form-check-input:checked ~ .form-check-label:last-of-type, .dark-mode .form-switch.price-switch .form-check-input:checked ~ .form-check-label:last-of-type {
  color: #fff;
}
.dark-mode .form-switch.form-switch-success .form-check-input:checked {
  background-color: #22c55e;
}
.dark-mode .form-control ~ .password-toggle-btn .password-toggle-indicator {
  color: rgba(255, 255, 255, 0.4);
}
.dark-mode .form-control ~ .password-toggle-btn .password-toggle-indicator:hover {
  color: rgba(255, 255, 255, 0.85);
}
.dark-mode .input-group-text {
  border-color: rgba(255, 255, 255, 0.18);
  background-color: rgba(255, 255, 255, 0.02);
  color: rgba(255, 255, 255, 0.7);
}
.dark-mode .range-slider {
  --si-range-slider-bg: rgba(255, 255, 255, 0.12);
  --si-range-slider-handle-bg: #eff2fc;
  --si-range-slider-pips-border-color: rgba(255, 255, 255, .2);
}
.dark-mode .input-group .input-group-text:first-of-type:not(:last-child) {
  border-right-color: transparent;
}
.dark-mode .input-group .input-group-text:last-of-type:not(:first-child) {
  border-left-color: transparent;
}
.dark-mode .input-group .input-group-text:first-child + .input-group-text {
  border-left-color: rgba(255, 255, 255, 0.18);
}
.dark-mode .was-validated :valid:not(:required):not(.btn),
.dark-mode .is-valid:not(:required):not(.btn) {
  border-color: rgba(255, 255, 255, 0.18) !important;
}
.dark-mode .credit-card-icon {
  background-position: 0 -26px;
}
.dark-mode .nav {
  --si-nav-link-color: rgba(255, 255, 255, 0.85);
  --si-nav-link-hover-color: #4c82f7;
  --si-nav-link-active-color: #4c82f7;
  --si-nav-link-disabled-color: rgba(255, 255, 255, 0.4);
}
.dark-mode .nav-tabs {
  --si-nav-tabs-link-bg: rgba(255, 255, 255, 0.05);
  --si-nav-tabs-link-hover-bg: rgba(76, 130, 247, 0.12);
  --si-nav-tabs-link-active-bg: #4c82f7;
  --si-nav-tabs-link-disabled-bg: rgba(255, 255, 255, 0.04);
  --si-nav-tabs-link-color: rgba(255, 255, 255, 0.85);
  --si-nav-tabs-link-hover-color: #4c82f7;
  --si-nav-tabs-link-active-color: #fff;
  --si-nav-tabs-link-disabled-color: rgba(255, 255, 255, 0.4);
}
.dark-mode .nav-tabs-alt {
  --si-nav-tabs-alt-border-color: rgba(255, 255, 255, 0.14);
}
.dark-mode .side-nav {
  --si-side-nav-border-color: rgba(255, 255, 255, 0.14);
}
.dark-mode .navbar {
  --si-navbar-stuck-bg: #000000;
  --si-navbar-brand-color: #fff;
  --si-navbar-brand-hover-color: #fff;
  --si-navbar-toggler-color: rgba(255, 255, 255, 0.85);
  --si-navbar-color: rgba(255, 255, 255, 0.85);
  --si-navbar-hover-color: #4c82f7;
  --si-navbar-disabled-color: rgba(255, 255, 255, 0.4);
  --si-navbar-active-color: #4c82f7;
}
.dark-mode .navbar-stuck {
  box-shadow: none;
}
.dark-mode .placeholder {
  opacity: 0.25;
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.1;
  }
}
.dark-mode .placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.9) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.9) 75%, #000 95%);
}
.dark-mode .card {
  --si-card-border-color: rgba(255, 255, 255, 0.14);
  --si-card-bg: #000000;
  --si-card-color: rgba(255, 255, 255, 0.7);
}
.dark-mode .card.shadow-sm, .dark-mode .card.shadow, .dark-mode .card.shadow-lg, .dark-mode .card.border-0 {
  background-color: var(--si-secondary-bg);
}
.dark-mode .card-hover:hover {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: var(--si-secondary-bg);
}
.dark-mode .card-hover.border-0:not(.bg-transparent):not(.bg-light) {
  border: var(--si-border-width) solid transparent !important;
}
.dark-mode .card-hover.border-0:not(.bg-transparent):not(.bg-light):hover {
  border-color: var(--si-card-border-color) !important;
}
.dark-mode .card-hover.bg-light:hover {
  background-color: var(--si-secondary-bg) !important;
}
.dark-mode .accordion {
  --si-accordion-border-color: rgba(255, 255, 255, 0.14);
  --si-accordion-bg: #000000;
  --si-accordion-btn-bg: #000000;
  --si-accordion-active-bg: #000000;
  --si-accordion-btn-color: #fff;
  --si-accordion-active-color: #fff;
  --si-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23fff'%3e%3cpath d='M225.813 48.907L128 146.72 30.187 48.907 0 79.093l128 128 128-128z'/%3e%3c/svg%3e");
  --si-accordion-btn-icon-box-bg: rgba(255, 255, 255, 0.05);
  --si-accordion-btn-icon-box-active-bg: #4c82f7;
}
.dark-mode .accordion-item.border-0 {
  background-color: var(--si-secondary-bg);
}
.dark-mode .accordion-button.shadow-none {
  background-color: transparent !important;
}
.dark-mode .popover {
  --si-popover-bg: #14171f;
  --si-popover-border-color: var(--si-border-color);
  --si-popover-body-color: var(--si-body-color);
  --si-popover-header-color: var(--si-heading-color);
  --si-popover-arrow-border: rgba(255, 255, 255, .2);
}
.dark-mode .modal {
  --si-modal-bg: #000000;
  --si-modal-border-color: rgba(255, 255, 255, 0.14);
  --si-modal-border-width: 1px;
  --si-modal-header-border-color: rgba(255, 255, 255, 0.14);
  --si-modal-footer-border-color: rgba(255, 255, 255, 0.14);
}
.dark-mode .alert-primary {
  --si-alert-bg: rgba(76, 130, 247, .05);
  --si-alert-border-color: rgba(76, 130, 247, .25);
}
.dark-mode .alert-secondary {
  --si-alert-bg: rgba(239, 242, 252, .05);
  --si-alert-border-color: rgba(239, 242, 252, .25);
}
.dark-mode .alert-success {
  --si-alert-bg: rgba(34, 197, 94, .05);
  --si-alert-border-color: rgba(34, 197, 94, .25);
}
.dark-mode .alert-info {
  --si-alert-bg: rgba(76, 130, 247, .05);
  --si-alert-border-color: rgba(76, 130, 247, .25);
}
.dark-mode .alert-warning {
  --si-alert-bg: rgba(255, 186, 8, .05);
  --si-alert-border-color: rgba(255, 186, 8, .25);
}
.dark-mode .alert-danger {
  --si-alert-bg: rgba(239, 68, 68, .05);
  --si-alert-border-color: rgba(239, 68, 68, .25);
}
.dark-mode .alert-light {
  --si-alert-bg: rgba(255, 255, 255, .05);
  --si-alert-border-color: rgba(255, 255, 255, .25);
}
.dark-mode .alert-dark {
  --si-alert-bg: rgba(11, 15, 25, .05);
  --si-alert-border-color: rgba(11, 15, 25, .25);
}
.dark-mode .alert-secondary,
.dark-mode .alert-light,
.dark-mode .alert-dark {
  --si-alert-border-color: var( --si-border-color);
}
.dark-mode .alert-secondary,
.dark-mode .alert-light {
  --si-alert-bg: var(--si-secondary-bg);
  --si-alert-color: var(--si-body-color);
  --si-alert-link-color: #fff;
}
.dark-mode .progress {
  --si-progress-bg: rgba(255, 255, 255, 0.08);
}
.dark-mode .progress-bar.bg-dark {
  --si-progress-bar-bg: #fff;
  --si-progress-bar-color: #000000;
  background-color: var(--si-progress-bar-bg) !important;
}
.dark-mode .progress-bar.bg-dark.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(11, 15, 25, 0.15) 25%, transparent 25%, transparent 50%, rgba(11, 15, 25, 0.15) 50%, rgba(11, 15, 25, 0.15) 75%, transparent 75%, transparent);
}
.dark-mode .list-group {
  --si-list-group-color: rgba(255, 255, 255, 0.7);
  --si-list-group-border-color: rgba(255, 255, 255, 0.14);
  --si-list-group-active-border-color: #4c82f7;
  --si-list-group-disabled-color: rgba(255, 255, 255, 0.5);
  --si-list-group-action-color: rgba(255, 255, 255, 0.85);
  --si-list-group-action-hover-color: #4c82f7;
}
.dark-mode .toast {
  --si-toast-bg: linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)), #000000;
  --si-toast-border-color: var(--si-border-color);
  background: var(--si-toast-bg);
}
.dark-mode .breadcrumb-item {
  --si-breadcrumb-item-active-color: #fff;
}
.dark-mode .pagination {
  --si-pagination-color: rgba(255, 255, 255, 0.85);
  --si-pagination-hover-color: #4c82f7;
  --si-pagination-hover-bg: rgba(255, 255, 255, 0.05);
  --si-pagination-focus-bg: rgba(255, 255, 255, 0.05);
  --si-pagination-disabled-color: rgba(255, 255, 255, 0.5);
}
.dark-mode .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.dark-mode .offcanvas, .dark-mode .offcanvas-sm, .dark-mode .offcanvas-md,
.dark-mode .offcanvas-lg, .dark-mode .offcanvas-xl, .dark-mode .offcanvas-xxl {
  --si-offcanvas-bg: #000000;
}
.dark-mode .offcanvas.bg-secondary, .dark-mode .offcanvas-sm.bg-secondary, .dark-mode .offcanvas-md.bg-secondary,
.dark-mode .offcanvas-lg.bg-secondary, .dark-mode .offcanvas-xl.bg-secondary, .dark-mode .offcanvas-xxl.bg-secondary {
  background-color: #14171f !important;
}
.dark-mode :not(pre) > code[class*=language-],
.dark-mode pre[class*=language-],
.dark-mode pre {
  --si-pre-bg: rgba(255, 255, 255, 0.04);
  --si-pre-line-numbers-border-color: rgba(255, 255, 255, 0.14);
  --si-pre-line-numbers-color: rgba(255, 255, 255, 0.5);
  --si-pre-tag-color: #5960f3;
  --si-pre-comment-color: rgba(255, 255, 255, .5);
  --si-pre-attr-name-color: #4da2f7;
  --si-pre-attr-value-color: #f33185;
  --si-pre-class-name-color: #4ddcda;
  box-shadow: none;
}
.dark-mode code[class*=language-],
.dark-mode pre[class*=language-],
.dark-mode pre > code,
.dark-mode pre {
  --si-pre-color: #fff;
}
.dark-mode kbd {
  background-color: black;
  color: #fff;
}
.dark-mode .btn-prev, .dark-mode .btn-next {
  --si-carousel-nav-btn-bg: rgba(255, 255, 255, 0.05);
  --si-carousel-nav-btn-color: #fff;
  --si-carousel-nav-btn-box-shadow: none;
  --si-carousel-nav-btn-disabled-color: rgba(255, 255, 255, .5);
}
.dark-mode .swiper-pagination {
  --si-carousel-pagination-bullet-bg: rgba(255, 255, 255, 0.5);
  --si-carousel-pagination-bullet-active-bg: #fff;
  --si-carousel-pagination-progressbar-bg: rgba(255, 255, 255, 0.14);
}
.dark-mode .swiper-scrollbar {
  --si-carousel-scrollbar-bg: rgba(255, 255, 255, 0.14);
  --si-carousel-scrollbar-drag-bg: #fff;
}
.dark-mode .offcanvas .swiper-scrollbar-drag, .dark-mode .offcanvas-sm .swiper-scrollbar-drag, .dark-mode .offcanvas-md .swiper-scrollbar-drag,
.dark-mode .offcanvas-lg .swiper-scrollbar-drag, .dark-mode .offcanvas-xl .swiper-scrollbar-drag, .dark-mode .offcanvas-xl .swiper-scrollbar-drag {
  opacity: 0.55;
}
.dark-mode .steps {
  --si-steps-number-bg: #181b24;
  --si-steps-number-inner-bg: rgba(255, 255, 255, 0.04);
}
.dark-mode .ap-seek-slider::-webkit-slider-runnable-track {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.55) var(--buffered-width), rgba(255, 255, 255, 0.3) var(--buffered-width));
}