#root {
    background-color: #000000;
    --gradX: 50%;
    --gradY: 100%;
    --c1: rgba(168, 239, 255, 1);
    --c2: rgba(0, 0, 0, 0.1);
}

.page-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .4s .2s ease-in-out;
    transition: all .4s .2s ease-in-out;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
}

.dark-mode .page-loading {
    background-color: #000000;
}

.page-loading.active {
    opacity: 1;
    visibility: visible;
}

.page-loading-inner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    opacity: 0;
}

.page-loading.active>.page-loading-inner {
    opacity: 1;
}

.page-loading-inner>span {
    display: block;
    font-size: 1rem;
    font-weight: normal;
    color: #9397ad;
}

.dark-mode .page-loading-inner>span {
    color: #fff;
    opacity: .6;
}

.page-spinner {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    margin-bottom: .75rem;
    vertical-align: text-bottom;
    border: .15em solid #b4b7c9;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner .75s linear infinite;
    animation: spinner .75s linear infinite;
}

.dark-mode .page-spinner {
    border-color: rgba(255, 255, 255, .4);
    border-right-color: transparent;
}

@-webkit-keyframes spinner {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

section {
    display: block
}

.block {

    grid-column: span 6;
}

.block {
    padding-bottom: 1rem;
}

@media (min-width: 768px) {
    #wrapper {
        display: grid;
        /* 1 */
        grid-template-columns: repeat(6, 1fr);
        /* 2 */
        grid-gap: 10px;
        /* 4 */
        ;
    }


    .block {
        grid-column: span 2;
        padding: 0
    }

    .wide {
        grid-column: span 3;
    }

    .extra-wide {
        grid-column: span 4;
    }


    .tall {
        grid-row: 2 / 4;
        /* 5 */
    }

}





@media (min-width: 768px) {
    .features-name {
        writing-mode: tb;
        transform: scale(-1, -1);
    }
}

@property --angle {
    syntax: '<angle>';
    initial-value: 90deg;
    inherits: true;
  }
  
  @property --gradX {
    syntax: '<percentage>';
    initial-value: 50%;
    inherits: true;
  }
  
  @property --gradY {
    syntax: '<percentage>';
    initial-value: 0%;
    inherits: true;
  }
  

.splash-section  {
	border-bottom: .35rem solid;
    border-image: radial-gradient(ellipse at var(--gradX) var(--gradY), var(--c1), var(--c1) 10%, var(--c2) 40%) 20;
    }

.hero-video-container {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 1) 90%);
    width: 100%;
    height: 100%;
}

.background-video {
    -webkit-text-size-adjust: 100%;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter, sans-serif;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    position: relative;
    height: 550px;
    color: white;
    z-index: -2;
    overflow: visible;
    width: 100%;
    background-image: linear-gradient(180deg, transparent 71%, #000 83%);
    background-clip: content-box;
    -webkit-text-fill-color: inherit;
}

.w-background-video>video {
    -webkit-text-size-adjust: 100%;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter, sans-serif;
    -webkit-box-direction: normal;
    color: white;
    -webkit-text-fill-color: inherit;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: baseline;
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100;
    background-image: url("https://uploads-ssl.webflow.com/61cf72b05dba90adc5370255/61d8762443b3e68038bdc4eb_Hero Video Uncompressed-poster-00001.jpg");
}

.rellax.a {
    /* background: linear-gradient(to right, #4c82f7, 90%, #0d6efd,100%, #0dcaf0); */
    background: linear-gradient(white 50%, black 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rellax.e {
    /* background: linear-gradient(to right, #4c82f7, 70%,#0d6efd, 90%, #0dcaf0); */
    background: linear-gradient(white 50%, black 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rellax.t {
    /* background: linear-gradient(to right, #4c82f7, 50%, #0d6efd, 70%, #0dcaf0); */
    background: linear-gradient(white 50%, black 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rellax.h {
    /* background: linear-gradient(to right, #4c82f7, 30%, #0d6efd, 50%, #0dcaf0); */
    background: linear-gradient(white 50%, black 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rellax.e {
    /* background: linear-gradient(to right, #4c82f7, 10%, #0d6efd, 30%, #0dcaf0); */
    background: linear-gradient(white 50%, black 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rellax.r {
    /* background: linear-gradient(to right, #4c82f7,0, #0d6efd, 10%, #0dcaf0); */
    background: linear-gradient(white 50%, black 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dashboard-image {
    filter: blur(3.5px);
}


.features-name {
    align-self: center;
    padding-bottom: 1rem;
    padding-left: 1rem;
}

.card {
    height: 100%;
    background: linear-gradient(42deg, rgba(130, 196, 242, .5) 0%, rgba(183, 155, 154, .5) 55%, rgba(233, 90, 43, .5) 100%);
}

.card-hover:hover {
    background: linear-gradient(42deg, rgba(130, 196, 242, 1) 0%, rgba(183, 155, 154, 1) 55%, rgba(233, 90, 43, 1) 100%);
}

.card-portfolio,
.card-portfolio:hover {
    background: transparent;
}

.list-group-item {
    border: none;
    border-radius: 15px;
    ;
}
@media (min-width: 700px) {
    .clarity-column,
    .steps-column {
        width: 50%;
    }
}

.step:not(:last-child) {
    background: linear-gradient(to right, #21298E, rgba(130, 196, 242, 1), rgba(233, 90, 43, 1));
    background-size: 100% 1px;
    background-position: bottom 0 left 41px;
    background-repeat: no-repeat;
}

.text-primary {
    color: silver !important;
}

.feature {
    flex-direction: column;
    background: grey;
}

.feature img {
    width: 100%;
}

.feature .card-header {
    border: none;
    margin: 0;
    color: darkblue;
}
